import React from "react";

import maps from "./icon/maps.svg"
import tel from "./icon/tel.svg"
import {
    Col,
    Row,
    Button,

} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import {toast} from "react-toastify";

import s from "./Dashboard.module.scss";


import {useGetPharmacyQuery} from "../../services/localServices";
import {Loader} from "../../components/Loader/Loader";
import {ErrorBox} from "../../components/Alert/Alert";
import Popup from "../../components/Notification/Popup";
import end_date from "./icon/end_date.svg"
import start_date from "./icon/driving.svg"
import walking from "./icon/walking.svg"
import {flagRetriever, formatDate} from "../../utilities/utilities";
import moment from "moment";
import {SucessBox} from "../../components/Alert/Sucess";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const options = {
    autoClose: 2000,
    closeButton: false,
    hideProgressBar: false,

};

function getItineraryTime(timeInSecond: number) {
    const duration = moment.duration(timeInSecond, 'seconds');
    const minutes = Math.floor(duration.asMinutes());
    const seconds = Math.floor(duration.asSeconds()) - minutes * 60;
    return `${minutes} min ${seconds} sec`
}

function metersToKilometers(meters: number) {
    return parseFloat((meters / 1000).toFixed(2))
}

function AskToEnableLocation(props: {
    children: React.ReactElement,
    location: Coordinates | null
}): React.ReactElement {
    const {children, location} = props
    if (location) {
        return children
    } else {
        return <p>
            Nous n'avons pas pu obtenir votre localisation, veuillez l'activer pour obtenir les statistiques de routes
        </p>
    }
}

function getItineraryLink({coordinates}: Pharmacy, location: Coordinates | null): string {
    if (location) {
        return `https://www.google.com/maps/dir/?api=1&origin=${location.latitude},${location.longitude}&destination=${coordinates.latitude},${coordinates.longitude}`
    } else {
        alert("Nous n'aviions pas pu obtenir votre localisation, vous seriez redirigé vers la page de la pharmacie sans l'itinéraire")
        return `https://www.google.com/maps?q=${coordinates.latitude},${coordinates.longitude}
`
    }
}
function areObjectsEqual(obj1: Object, obj2: Object) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

const PharmacyGard = (props: { userLocation: Coordinates | null }) => {
    const {userLocation} = props

    // @ts-ignore
    const {data, isFetching, error}: {
        data: {
            pharmacies: Pharmacy[],
            success: boolean,
        }, isFetching: boolean, error: any
    } = useGetPharmacyQuery(userLocation);
    const callText = isMobile ? "Appeler" : "Copier le numéro"

    const onCall = (phone) => {

        if (isMobile) {
            window.open(`tel:${phone}`, "_blank")

        } else {
            navigator.clipboard.writeText(phone).then(() => {
                toast(<Popup type={"success"} message={"Vérifiez votre presse papier"} withIcon/>, options)
            })
        }
    }
    if (isFetching) return <Loader center={true}/>
    if (error) {
        console.log(error)
        return <ErrorBox message={error}/>

    }

    const {pharmacies}: {
        pharmacies: Pharmacy[]
    } = data
    const minDistancesByWalking = [...pharmacies]
        .sort((a, b) => a.walking_time - b.walking_time)[0]
    const minDistancesByDriving = [...pharmacies]
        .sort((a, b) => a.driving_time - b.driving_time)[0]
    return (
        <div>
            <SucessBox message={"La pharmacie la plus proche de vous, que vous soyez à pied ou en voiture, est mise en évidence."} />
            <Row>
                {
                    pharmacies

                        .map(
                        pharmacy =>
                            <Col xs={12} md={6} xl={4} className="mt-4 mb-4 mt-lg-0
                            ">
                                <Widget className="widget-p-md justify-content-between">
                                    <div className="headline-2 px-3">


                                        <p><img className="img-fluid mr-3 rounded" alt="Icone associé au services"
                                                src={flagRetriever('france')}
                                                width="20" height="20"
                                        />Pharmacie {pharmacy.name}  </p>
                                        <p><img className="img-fluid mr-3 rounded"
                                                alt="Icone associé au services"
                                                src={flagRetriever('maroc')}
                                                width="20" height="20"
                                        /><span dir="rtl" lang="ar"/>{pharmacy.arabic_name}</p>
                                    </div>


                                    <div className={`my-4 ${s.widgetBlock}`}>
                                        <div className={`${s.widgetBody}`}>
                                            <div className="d-flex w-100">
                                                <img className="img-fluid mr-3 rounded" alt="Icone associé au services"
                                                     src={end_date}
                                                     width="50" height="50"
                                                />
                                                <div className="d-flex flex-column overflow-auto" style={{
                                                    height: "50px",
                                                    width: "100%"
                                                }}>
                                                    <b>Date de fin de garde</b>
                                                    <p>{formatDate(pharmacy.end_date)}</p>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className={`my-4 ${s.widgetBlock} ${areObjectsEqual(pharmacy, minDistancesByDriving) && userLocation && s.best}`}>
                                        <div className={`${s.widgetBody}`}>
                                            <div className="d-flex w-100 bg">
                                                <img className="img-fluid mr-3 rounded" alt="Icone associé au services"
                                                     src={start_date}
                                                     width="50" height="50"
                                                />
                                                <div className="d-flex flex-column overflow-auto" style={{
                                                    height: "100px",
                                                    width: "100%"
                                                }}>
                                                    <AskToEnableLocation location={userLocation}>
                                                        <React.Fragment>
                                                            <p><b>Temps de route en voiture</b></p>
                                                            <p>{getItineraryTime(pharmacy.driving_time)}</p>
                                                            <p><b>Distance</b></p>
                                                            <p>Distance: {metersToKilometers(pharmacy.driving_distances)}km</p>
                                                        </React.Fragment>
                                                    </AskToEnableLocation>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className={`my-4 ${s.widgetBlock} ${areObjectsEqual(pharmacy, minDistancesByWalking) && userLocation&& s.best}`}>
                                        <div className={`${s.widgetBody}`}>
                                            <div className="d-flex w-100">
                                                <img className="img-fluid mr-3 rounded" alt="Icone associé au services"
                                                     src={walking}
                                                     width="50" height="50"
                                                />
                                                <div className="d-flex flex-column overflow-auto" style={{
                                                    height: "100px",
                                                    width: "100%"
                                                }}>
                                                    <AskToEnableLocation location={userLocation}>
                                                        <React.Fragment>

                                                            <p><b>Temps de route à pied</b></p>
                                                            <p>{getItineraryTime(pharmacy.walking_time)}</p>
                                                            <p><b>Distance</b></p>
                                                            <p>{metersToKilometers(pharmacy.walking_distances)}km</p>
                                                        </React.Fragment>
                                                    </AskToEnableLocation>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                    <div className={"mb-2 d-flex justify-content-center w-100"}>
                                        <Button
                                            color="primary"
                                            outline={true}
                                            className=" rounded-pill "
                                            onClick={() => {
                                                window.open(getItineraryLink(pharmacy, userLocation), "_blank")
                                            }}
                                        >
                                            <div className="d-flex">
                                                <img src={maps} alt="..." width={20} height={20}/>
                                                <div className="ml-2 body-2">Trouver sur google Maps</div>
                                            </div>
                                        </Button>
                                    </div>

                                    <div>
                                        <Button
                                            color="primary"
                                            className="notification-btn rounded-pill "
                                            onClick={() => onCall(
                                                pharmacy.tel
                                            )}
                                        >
                                            <div className="d-flex">
                                                <img src={tel} alt="..." width={20} height={20}/>
                                                <div className="ml-2 body-2">{callText}</div>
                                            </div>
                                        </Button>
                                    </div>

                                </Widget>
                            </Col>
                    )
                }


            </Row>

        </div>
    )
}

export default PharmacyGard;
