import React from "react";


import "eva-icons/style/eva-icons.css";
import "font-awesome/css/font-awesome.min.css";


import {
    Col,
    Row,

} from "reactstrap";

import MensualChart from "./components/MensualChart";
import UserTable from "./components/UserTable";


const Cesamien = () => {
    return (
        <div>

            <Row>
                <Col className="pr-grid-col" xs={12} lg={12}>
                    <MensualChart/>
                    <UserTable/>
                </Col>
            </Row>
        </div>
    );
}

export default Cesamien;
