import React, {useState} from "react";


import s from "../Evenements/Evenement.module.scss";

import {
    useGetAllActivitiesQuery,
} from "../../services/activity";
import {Loader} from "../Loader/Loader";
import {ErrorBox} from "../Alert/Alert";
import Empty from "../Empty/Empty";
import {Button} from "reactstrap";
import {blurBackground, unBlurBackground} from "../../slices/navigation";
import {useDispatch} from "react-redux";
import Confirmation from "./Confirmation/Confirmation";
import ScrollableModal from "../ScrollableModal/ScrollableModal";


export default function Evenement({user}: { user: User }) {
    const {data, isFetching, error, refetch} = useGetAllActivitiesQuery(false);
    const [activityToggled, setActivityToggled] = useState(false)


    const [selectedActivity, setSelectedActivity] = useState<{ activity: Activity | null, icon: string }>({
        activity: null,
        icon: ""
    });
    const [openActivity, setOpenActivity] = useState(false);
    const dispatch = useDispatch();

    const handleOpenActivity = (e) => {
        const activity = JSON.parse(e.target.dataset.activity);
        setSelectedActivity({
            activity,
            icon: e.target.dataset.icon
        });
        setOpenActivity(true);
        dispatch(blurBackground())
    };

    const handleCloseActivity = () => {
        if (activityToggled) {
            refetch()
        }
        setSelectedActivity({
            activity: null,
            icon: ""
        })
        setOpenActivity(false);
        dispatch(unBlurBackground())

    };
    if (isFetching) return <div className={`mt-3 ${s.widgetBlock}`}><Loader/></div>
    if (error) {
        console.log(error)
        // @ts-ignore
        return <ErrorBox message={error.data.message}/>
    }

    const checkIfSubscribed = (activity) => {
        return activity["participants"]
            .some((participant) => {
                return participant.user_mail === user.mail
            })

    }
    const activities = data.data.map((activity) => {
        return {
            ...activity,
            isSubscribed: checkIfSubscribed(activity)
        }
    })
        .filter((activity) => {
            return activity.actif
        })
        .filter((activity) => {
            return !activity.isSubscribed
        })
    const content = activities.length > 0 ?
        activities
            .map((key, index) => {
                return (
                    <div key={index} className={`mt-3 ${s.widgetBlock}`}>
                        <ScrollableModal
                            onClose={handleCloseActivity}
                            open={openActivity}

                        >

                            <Confirmation activity={selectedActivity.activity} icon={selectedActivity.icon}
                                          user={user}
                                          setActivityToggled={setActivityToggled}
                            />
                        </ScrollableModal>
                        <div className={`${s.widgetBody} d-flex flex-column`}>
                            <div className="d-flex w-100 justify-content-start align-items-center">

                                <img className={`img-fluid mr-3 ${s.rdImg}`} src={key.picture_link}
                                     alt="sorry!!!"/>
                                <div className="d-flex flex-column">
                                    <p className="body-2 "> {key.name}</p>
                                    <p className="body-3 muted">{key.day} à {key.time}</p>
                                    <p className="body-1 muted-dark">{key["price"] === 0 ?
                                        <strong>Gratuit</strong> : `${key["price"]} DH`} </p>
                                </div>

                            </div>
                            <div className={"w-100 d-flex align-items-center"}>
                                <Button className={"w-100 mt-2 "} onClick={handleOpenActivity}
                                        data-activity={JSON.stringify(key)}
                                        data-icon={key["picture_link"]}

                                >Voir</Button>

                            </div>


                        </div>
                    </div>

                )
            }) : <Empty text="Aucun événement à afficher" style={"text-dark"}/>
    return (
        <div>
            {content}
        </div>


    );
}


