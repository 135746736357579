import Widget from "../../../components/Widget/Widget";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Button, Col, FormGroup, Row} from "reactstrap";
import {useUpdateUserMutation} from "../../../services/user";
import {Loader} from "../../../components/Loader/Loader";
import moment from "moment"
import {SucessBox} from "../../../components/Alert/Sucess";
import {ErrorBox} from "../../../components/Alert/Alert";

export default function AddNotification({cesamiens, setMustRefetch}: { cesamiens: User[], setMustRefetch: any }) {
    const {register, handleSubmit} = useForm();
    const [updateUser, {isLoading}] = useUpdateUserMutation()
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const submitForm = async (data) => {
        const notSent: string[] = []
        for (let i = 0; i < cesamiens.length; i++) {
            const toSend: NotificationInterface[] =
                [...cesamiens[i].notification, {
                    title: data.title,
                    content: data.content,
                    type: data.Type,
                    date: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
                }]


            const formData = new FormData();
            formData.append('notification', JSON.stringify(toSend))

            try {
                const userData = {
                    user_id: cesamiens[i].id,
                    data: formData
                }
                await updateUser(userData)
                    .unwrap()
                    .then(() => {
                        console.log("done")
                    })
                    .catch(() => {
                        notSent.push(cesamiens[i].mail)
                    })
            } catch (e) {
                notSent.push(cesamiens[i].mail)
            }
        }
        if (notSent.length > 0) {
            // @ts-ignore
            setErrorMessage(`Les notifications n'ont pas été envoyées à ${notSent.join(", ")}`)
            setSuccessMessage(null)
        } else {
            // @ts-ignore
            setSuccessMessage("Les notifications ont été envoyées avec succès")
            setErrorMessage(null)
        }
        setMustRefetch(true)
    }


    return (
        <div>
            <Widget className="widget-p-md ">
                <form onSubmit={handleSubmit(submitForm)}>

                    <FormGroup className=" mr-3 mb-4">
                        <Row>
                            <Col xs={12} className={"mb-2"}>
                                <p className={"body-2 text-center"}>Titre de La Notification</p>
                                <input
                                    className="form-control input-transparent mt-2 pl-3"
                                    type="text"

                                    {...register('title')}
                                    required
                                />
                            </Col>

                        </Row>

                        <Row>
                            <Col xs={12} className={"mb-2"}>
                                <p className={"body-2  text-center my-2"}>Type de notification</p>

                                <select  id="" {...register('Type')} className={"form-control"}>
                                    <option value="info">Information</option>
                                    <option value="warning">Avertissement</option>
                                    <option value="error">Erreur</option>
                                    <option value="planning">Planification</option>
                                </select>

                            </Col>

                            <Col xs={12}>
                                <p className={"body-2 text-center my-2"}>Contenu</p>
                                <textarea className={"form-control"} cols={30} rows={10} {...register('content')} required/>


                            </Col>
                        </Row>

                    </FormGroup>
                    <FormGroup className=" mr-3">
                        {successMessage && <Row>
                            <Col className={"d-flex align-items-center justify-content-center"}>
                                <SucessBox message={successMessage}/>
                            </Col>
                        </Row>}
                        {errorMessage && <Row>
                            <Col className={"d-flex align-items-center justify-content-center"}>
                                <ErrorBox message={errorMessage}/>
                            </Col>
                        </Row>}
                        <Row>
                            <Col className={"d-flex align-items-center justify-content-center"}>
                                <Button className="rounded-pill my-3  " type="submit"
                                        color="secondary-red">Envoyer Notification</Button>
                            </Col>
                            {isLoading && <Loader/>}
                        </Row>


                    </FormGroup>
                </form>


            </Widget>

        </div>
    )
}