import React, {useState} from "react";
import Creation from "./components/action/creation.js";
import Edit from "./components/action/Edit";
import s from "../../components/Header/Header.module.scss";
import "eva-icons/style/eva-icons.css";

import {v4 as uuidv4} from "uuid";
import {
    Col,
    Row,
    Table,

} from "reactstrap";

import Widget from "../../components/Widget/Widget";
import act from "./activite.module.scss";
import {Loader} from "../../components/Loader/Loader";
import {ErrorBox} from "../../components/Alert/Alert";
import {useDispatch} from "react-redux";
import {blurBackground, unBlurBackground} from "../../slices/navigation";
import ScrollableModal from "../../components/ScrollableModal/ScrollableModal";
import {IconButton} from "@material-ui/core";
import {
    useDeleteActivitiesMutation,
    useGetAllActivitiesQuery
} from "../../services/activity";
import alert from "reactstrap/lib/Alert";
import Empty from "../../components/Empty/Empty";
import ListUser from "./components/action/listUser";


const Activite = () => {

    const {data: activities, isFetching, error, refetch} = useGetAllActivitiesQuery(true)
    const [deleteActivity, {isLoading: isDeletingActivity}] = useDeleteActivitiesMutation()

    const dispatch = useDispatch();
    const [selectedActivity, setSelectedActivity] = useState({})
    const [openCreation, setOpenCreation] = React.useState(false);
    const [openListUser, setOpenListUser] = React.useState(false);
    const [openEdition, setOpenEdition] = React.useState(false);
    const [activityEdited, setActivityEdited] = useState(false)
    const handleCloseCreation = () => {
        if (activityEdited) {
            setActivityEdited(false)
            refetch()
        }
        setOpenCreation(false);
        dispatch(unBlurBackground())
    };
    const handleOpenCreation = () => {
        setOpenCreation(true);
        dispatch(blurBackground())
    };
    const handleCloseListUser = () => {

        setOpenListUser(false);
        dispatch(unBlurBackground())
    };
    const handleOpenListUser = (activity) => {
        setSelectedActivity(activity)
        setOpenListUser(true);
        dispatch(blurBackground())
    };

    const handleCloseEdition = () => {
        if (activityEdited) {
            setActivityEdited(false)
            refetch()
        }
        setOpenEdition(false);
        dispatch(unBlurBackground())
    };
    const handleOpenEdition = (activity) => {
        setSelectedActivity(activity)
        setOpenEdition(true);
        dispatch(blurBackground())
    };

    const deleteActivities = ({id}: Activity) => {
        const confirm = window.confirm("Voulez-vous vraiment supprimer cette activité ?")
        if (!confirm) return
        try {

            deleteActivity(id)
                .unwrap()
                .then(() => {
                    refetch()
                })
                .catch((e) => {
                    console.log(e)
                    // @ts-ignore
                    alert(`Une erreur s'est produite lors de la suppression de l'activité`)

                })
        } catch (e) {
            console.log(e)
            // @ts-ignore
            alert(`Une erreur s'est produite lors de la suppression de l'activité`)

        }
    }


    const content = function () {
        if (isFetching) return <Loader center={true} className={"mt-4"}/>
        if (error) { // @ts-ignore
            return <ErrorBox message={error.data.message}/>
        }
        return activities.data.length === 0 ? <Empty text={"Aucune activité"} style={"ml-2"}/> :
            activities.data
                .map(item => (
                    <tr key={uuidv4()}>

                        <td className="d-flex align-items-center  ml-2"><img
                            className={act.image} src={item.picture_link}
                            alt="User"/><span
                            className="ml-3">{item.name}</span></td>
                        <td>{item.date}</td>
                        <td>{item.price}</td>

                        <td className={`d-flex ${s.userRow} align-items-center justify-content-end `}>


                            <IconButton className={s.iconButton}
                                        onClick={() =>
                                            handleOpenEdition(item)}
                            >
                                <i className="eva eva-edit"/>
                            </IconButton>
                            <IconButton className={s.iconButton}
                                        onClick={() => deleteActivities(item)}
                            >

                                <i className="eva eva-close
                                                     mx-2"></i>
                            </IconButton>
                            <IconButton className={s.iconButton}
                                        onClick={() => handleOpenListUser(item)}

                            >

                                <i className="eva eva-list-outline
                                                     mx-2"></i>
                            </IconButton>

                        </td>

                    </tr>
                ))
    }
    return (
        <div>
            <ScrollableModal
                onClose={handleCloseEdition}
                open={openEdition}
            >
                <Edit
                    activity={selectedActivity}
                    setActivityEdited={setActivityEdited}/>
            </ScrollableModal>
            <ScrollableModal
                onClose={handleCloseCreation}
                open={openCreation}
            >
                <Creation
                    setActivityEdited={setActivityEdited}
                />
            </ScrollableModal>
            <ScrollableModal
                onClose={handleCloseCreation}
                open={openCreation}
            >
                <Creation
                    setActivityEdited={setActivityEdited}
                />
            </ScrollableModal>
            <ScrollableModal
                onClose={handleCloseListUser}
                open={openListUser}
            >
                { selectedActivity["participants"] && selectedActivity["participants"].length > 0 ?
                    <div>
                        <ListUser
                            data={selectedActivity["participants"]}
                        />
                    </div> : <Empty text={"Aucun participant"} style={"text-dark"}/>}

            </ScrollableModal>
            <Row>
                <Col className="pr-grid-col" xs={12} lg={12}>

                    <Row className="gutter mb-4">
                        <Col>

                            <Widget>
                                <div className={act.tableTitle}>
                                    <div className="headline-2">Activités</div>
                                    <div className="d-flex">
                                        <div onClick={handleOpenCreation}><i className="eva eva-plus">
                                        </i>
                                        </div>

                                    </div>
                                </div>
                                <div className="widget-table-overflow">
                                    <Table className={`table-striped table-borderless table-hover ${act.statesTable}`}
                                           responsive>
                                        <thead>
                                            <tr>

                                                <th className="w-40 ml-2">NOM</th>
                                                <th className="w-30">DATE</th>
                                                <th className="w-30">PRIX</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {content()}

                                        </tbody>
                                    </Table>

                                </div>
                            </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default Activite;
