import React, {useState} from "react";
import {Alert} from "reactstrap";

import infoIcon from "../../assets/notificationsIcons/infoIcon.svg";
import warningIcon from "../../assets/notificationsIcons/warningIcon.svg";
import successIcon from "../../assets/notificationsIcons/successIcon.svg";
import dangerIcon from "../../assets/notificationsIcons/dangerIcon.svg";

import s from "./Notification.module.scss";

const typesIcons = {
    info: infoIcon,
    warning: warningIcon,
    success: successIcon,
    error: dangerIcon,
}

function notificationMessages(message, type) {

    return {
        info: `<span class='body-2'>Info:</span> ${message}`,
        warning: `<span class='body-2'>Warning:</span>  ${message}`,
        success: `<span class='body-2'>Success:</span>  ${message}`,
        error: `<span class='body-2'>Danger:</span>  ${message}`,
    }[type]
}

const colors = {
    info: "#00A5FF",
    warning: "#FFA100",
    success: "#43BC13",
    error: "#FF4B23",
}

export default function Popup({type, message, withIcon}) {

    const [notificationOpen, setNotificationClose] = useState(true)

    const icon = getIconByType(type)

    const closeNotification = () => {
        setNotificationClose(!notificationOpen)
    }

    return (
        <>
            <Alert
                className={s.notificationContainer}
                style={{backgroundColor: colors[type]}}
                isOpen={notificationOpen}
                toggle={() => closeNotification()}
            >
                <div className={s.notificationIconContainer}>
                    {withIcon && <img src={icon} alt="..."/>}
                </div>
                <div className={s.messageContainer}>
                    <span dangerouslySetInnerHTML={{__html: notificationMessages(message, type)}}></span>
                </div>
            </Alert>
        </>
    )
};

function getIconByType(type = "info") {
    return typesIcons[type];
}

