import Widget from "../../Widget/Widget";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import sDashboard from "../../../pages/dashboard/Dashboard.module.scss";
import {Button, Col, FormGroup, FormText, Row} from "reactstrap";
import warningIcon from "../icons/warning.svg";
import s from "../../../pages/dashboard/Dashboard.module.scss";
import sCustom from "./style.module.scss"
import ROLES from "../../../security/Roles";
import {useUpdateUserMutation} from "../../../services/user";
import {Loader} from "../../Loader/Loader";
import {ErrorBox} from "../../Alert/Alert";
import {SucessBox} from "../../Alert/Sucess";
import {IconButton} from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {resizeFile} from "../../../utilities/file";


export default function UpdateProfile({user}) {
    const {register, handleSubmit} = useForm();
    const [updateUser, {isLoading}] = useUpdateUserMutation()
    const [errorMessage, setErrorMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const [showPassword, setShowPassword] = React.useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const submitForm = (data) => {
        if (data.password !== data["password_confirmation"]) {
            setErrorMessage("Les mots de passe ne correspondent pas")
            setSuccessMessage(null)
            return
        }
        setErrorMessage(null)
        const formData = new FormData();
        if (data.picture.length > 0) {
            if (!data.picture[0].type.match("image.*")) {
                setErrorMessage("Le fichier doit être une image")
                setSuccessMessage(null)
                return
            }
            formData.append("picture", data.picture[0])
        }
        if (data.password !== "") {
            formData.append("password", data.password)
        }
        if (data["amciIdentifier"] !== user["amciIdentifier"]) {
            formData.append("amciIdentifier", data["amciIdentifier"])
        }

        if (formData.entries().next().done) {
            setErrorMessage("Aucune modification n'a été effectuée")
        } else {

            try {
                const userData = {
                    user_id: user.id,
                    data: formData
                }
                updateUser(userData)
                    .unwrap()
                    .then(() => {
                        setSuccessMessage("Les modifications ont été effectuées avec succès, veuillez " +
                            "actualiser la page pour voir les modifications")
                        setErrorMessage(null)
                    })
                    .catch((e) => {
                        setErrorMessage(e)
                    })
            } catch (e) {
                setErrorMessage(e)
                setSuccessMessage(null)
            }
        }
    }

    return (
        <div>
            <Widget className="widget-p-md ">
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className="d-flex flex-row justify-content-center align-items-center mb-4">
                        <div>
                            <img id="profile_pic" className={`${s.image} ${s.userpic} text-center `}
                                 src={user["picture_link"]} alt="..."/>
                        </div>
                        <div className={`${s.userInfo} d-flex justify-content-center  align-items-center mt-3`}>
                            <p className="headline-2 text-center "> Editer la photo de profile</p>
                            <p className="headline-3 text-center justify-content-center w-75  align-items-center mt-3 ">
                                <input
                                    className={`form-control ${sCustom.fileControl}   `}
                                    type="file"
                                    id={"file-input"}
                                    accept={"image/*"}
                                    {...register('picture')}
                                    onChange={async (e) => {


                                        const image = await resizeFile(e.target.files[0],
                                            document.getElementById("profile_pic"))

                                        /* Put image in file input */
                                        const file = new File([image], "profile_pic", {type: image.type})
                                        /* Create a FileList */
                                        const list = new DataTransfer()
                                        list.items.add(file)
                                        e.target.files = list.files


                                    }}
                                />
                            </p>
                        </div>
                    </div>
                    <FormGroup className=" mr-3 mb-4">

                        <FormText className={"body-2"}>Edition du mot de passe</FormText>
                        <div className={`my-2 ${sDashboard.widgetBlock}`}>
                            <div className={sDashboard.widgetBody}>
                                <div className="d-flex">

                                    <img className="img-fluid mr-3 " alt={".."} src={warningIcon} width={40}
                                         height={40}/>
                                    <div className="d-flex flex-column">

                                        <p className="body-3 muted"> Pour des raisons de securités en cas de perte de
                                            votre mot de passe, nous ne
                                            pouvons pas vous le renvoyer. Vous devez donc le modifier.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <Row>
                            <Col xs={12} lg={6} className={"mb-2 d-flex align-items-center"}>
                                <input
                                    className="form-control input-transparent mt-2 pl-3"
                                    type={showPassword ? 'text' : 'password'}

                                    {...register('password')}
                                    placeholder="Nouveau mot de passe"
                                />
                                <IconButton onClick={togglePasswordVisibility}
                                            className={`${s.togglePasswordVisibility} ml-2`}>
                                    {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                </IconButton>
                            </Col>
                            <Col xs={12} lg={6} className={"mb-2 d-flex align-items-center"}>
                                <input
                                    className="form-control input-transparent mt-2 pl-3"
                                    type={showPassword ? 'text' : 'password'}

                                    {...register('password_confirmation')}
                                    placeholder="Confirmation mot de passe"

                                />
                                <IconButton onClick={togglePasswordVisibility}
                                            className={`${s.togglePasswordVisibility} ml-2`}>
                                    {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                </IconButton>
                            </Col>
                        </Row>

                    </FormGroup>
                    <FormGroup className=" mr-3">
                        {user.Type !== ROLES.USER &&
                            <FormText className={"body-2"}>Edition du code amci</FormText>}
                        {user.Type !== ROLES.USER &&
                            <Row>
                                <Col>
                                    <input
                                        className="form-control input-transparent mt-2 pl-3"
                                        type="text"

                                        placeholder={user["amciIdentifier"]}
                                        {...register('amciIdentifier')}
                                        defaultValue={user["amciIdentifier"]}
                                    />
                                </Col>
                            </Row>}
                        {errorMessage !== null && <div className={"mt-3"}>
                            <ErrorBox message={`${errorMessage}`}/>

                        </div>} {successMessage !== null && <div className={"mt-3"}>
                        <SucessBox message={`${successMessage}`}/>

                    </div>}

                        <Row className={"d-flex justify-content-center align-items-center"}>

                            <Col xs={9} className={"d-flex align-items-center justify-content-center"}>
                                <Button className="rounded-pill my-3  " type="submit"
                                        color="secondary-red">Sauvegarder les modifications</Button>
                            </Col>
                            {isLoading && <Col><Loader/></Col>}
                            {successMessage &&
                                <Col>
                                    <IconButton className={sCustom.refreshButton}
                                                onClick={() => {
                                                    window.location.reload()
                                                }}
                                    >
                                        <RefreshIcon

                                        />
                                    </IconButton>
                                </Col>

                            }
                        </Row>

                    </FormGroup>
                </form>


            </Widget>

        </div>
    )
}