import { Button, Col, Label, Row, Table } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import act from "../../activity/activite.module.scss";
import { v4 as uuidv4 } from "uuid";
import s from "../../../components/Header/Header.module.scss";
import { IconButton } from "@material-ui/core";
import Paginator from "./Paginator";
import sDashboard from "../../dashboard/Dashboard.module.scss";
import identifier from "../../../components/Amci/icons/identifier.svg";
import name from "../../../components/Amci/icons/name.svg";
import money from "../../../components/Amci/icons/money.svg";
import { useState } from "react";
import SearchBar from "../../../components/SearchBar/SearchBar";
import Empty from "../../../components/Empty/Empty";
import { flagRetriever, highlightSearchTerm } from "../../../utilities/utilities";



function UserDataDisplay({ data, handleOpenCreation, handleOpenContribution, handleOpenNotification }) {
    const pageSize = 6;
    const users = data;
    const [currentPage, setCurrentPage] = useState(0);
    const [search, setSearch] = useState("");


    const setTablePage = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
    }
    const makeSearch = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
        setCurrentPage(0)
    }

    const filteredUsers = users
        .filter(item => item.name.toLowerCase().includes(search.toLowerCase())
            || item.surname.toLowerCase().includes(search.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name) || a.surname.localeCompare(b.surname))

    const pagesCount = Math.ceil(filteredUsers.length / pageSize);
    const sortedUsers = filteredUsers.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

    return (
        <Row className="gutter mb-4">
            <Col className={"d-none d-lg-block"}>

                {/* ************************************************************** */}
                <Widget>
                    <SearchBar placeholder={"Nom ou Prénom"}
                        onChange={makeSearch}

                    />

                    <div className={act.tableTitle}>
                        <div className="headline-2">Liste des CESAMIENS</div>
                        <div className={"d-flex"}>
                            <IconButton className={s.iconButton}
                                onClick={handleOpenCreation}>

                                <i className="eva eva-plus
                                                     mx-2"></i>
                            </IconButton>
                            <IconButton className={s.iconButton}
                                onClick={() => handleOpenNotification(filteredUsers)}

                            >

                                <i className="eva eva-bell-outline
                                                     mx-2"></i>
                            </IconButton>
                        </div>

                    </div>
                    {sortedUsers.length > 0 ?
                        <div className="widget-table-overflow">

                            <Table className={`table-striped table-borderless table-hover ${act.statesTable}`}
                                responsive>
                                <thead>
                                    <tr>

                                        <th className="w-20">MATRICULE</th>
                                        <th className="w-20">NOM</th>
                                        <th className="w-20">PRENOM</th>
                                        <th className="w-20">PAYS</th>
                                        <th className="w-20">Status Cotisation</th>
                                        <th className="w-20">Opération</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedUsers.map(item => (
                                        <tr key={uuidv4()}>


                                            <td><span
                                                className="ml-3">{item.amciIdentifier}</span></td>
                                            <td>{highlightSearchTerm(item.name, search)}</td>
                                            <td>{highlightSearchTerm(item.surname, search)}</td>
                                            <td>{item.nationality}</td>
                                            <td>
                                                {item.is_up_to_date === 0 ?
                                                    <Label className="label label-success text-success">Cotisation à
                                                        jour</Label> :
                                                    <Label className="label label-danger text-danger">Cotisation en
                                                        retard
                                                        de {item.is_up_to_date} mois</Label>}
                                            </td>
                                            <td className={`d-flex ${s.userRow} align-items-center `}>


                                                <IconButton className={s.iconButton}
                                                    onClick={() => handleOpenContribution(item.contribution, item)}
                                                >
                                                    <i className="fa fa-euro mx-2" />
                                                </IconButton>
                                                <IconButton className={s.iconButton}
                                                    onClick={() => handleOpenNotification([item])}>

                                                    <i className="eva eva-bell-outline
                                                     mx-2"></i>
                                                </IconButton>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Paginator currentPage={currentPage} pagesCount={pagesCount}
                                setTablePage={setTablePage} />
                        </div> : <Empty style={"mx-3"} text={"Aucun utilisateur trouvé"} />}

                </Widget>

            </Col>
            <Col className={"d-block d-lg-none"}>
                <Widget className="" style={{ background: "none" }}>
                    <div className={"bg-white rounded mb-4 mt-3"}>
                        <div className={"d-flex flex-column "}>
                            <SearchBar placeholder={"Rechercher un utilisateur"}
                                onFocus={() => {
                                    document.querySelector("body").classList.add("overflow-hidden")

                                    document.querySelector(".to-view").scrollIntoView({ alignToTop: true })
                                }
                                }
                                onBlur={() => {
                                    document.querySelector("body").classList.remove("overflow-hidden")
                                }}
                                onChange={makeSearch} className={"order-1 order-md-0 my-2 to-view"}
                            />
                            <div className={`${act.tableTitle} order-0 order-md-0 d-flex flex-column`}>
                                <div className="headline-2 text-center">Liste des CESAMIENS</div>
                                <div className={"d-flex my-2 flex-column align-items-center"}

                                >

                                    <Button className={"w-100 m-2 "} color={"success"} onClick={handleOpenCreation}

                                    >Liens d'ajout des cesamiens</Button>
                                    <Button className={"w-100 mt-2 "} color={"primary"} onClick={() => handleOpenNotification(filteredUsers)}

                                    >
                                        Envoyer une Notification aux cesamiens
                                    </Button>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className={`d-flex justify-content-between ${sDashboard.thinScrollbar} mb-2`} style={
                        {
                            width: "100%",
                            overflowX: "scroll",
                        }
                    }>
                        {
                            sortedUsers.length > 0 ? sortedUsers.map(item =>
                                <div className={"bg-white p-4 my-4 mx-1 rounded "} key={uuidv4()}
                                    style={
                                        {
                                            minWidth: "300px",
                                        }
                                    }
                                >

                                    <div className={`mt-3  `}>

                                        <div className={`mt-4 ${sDashboard.widgetBlock}`}>
                                            <div className={sDashboard.widgetBody}>
                                                <div className="d-flex">

                                                    <div className="d-flex flex-column">
                                                        <p className="body-2 mb-2">MATRICULE</p>
                                                        <p className="body-3 muted">{item.amciIdentifier}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={`mt-4 ${sDashboard.widgetBlock}`}>
                                            <div className={sDashboard.widgetBody}>
                                                <div className="d-flex">
                                                    <img className={` mr-3 ${act.image}`} alt="..." src={identifier}
                                                    />

                                                    <div className="d-flex flex-column">
                                                        <p className="body-2 mb-2">NOM</p>
                                                        <p className="body-3 muted">{highlightSearchTerm(item.name, search)}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={`mt-4 ${sDashboard.widgetBlock}`}>
                                            <div className={sDashboard.widgetBody}>
                                                <div className="d-flex">
                                                    <img className={` mr-3 ${act.image}`} alt="..." src={name}
                                                    />

                                                    <div className="d-flex flex-column">
                                                        <p className="body-2 mb-2">PRENOM</p>
                                                        <p className="body-3 muted">{highlightSearchTerm(item.surname, search)}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={`mt-4 ${sDashboard.widgetBlock}`}>
                                            <div className={sDashboard.widgetBody}>
                                                <div className="d-flex">
                                                    <img className={` mr-3 ${act.image}`} alt="..."
                                                        src={flagRetriever(item.nationality)}
                                                    />

                                                    <div className="d-flex flex-column">
                                                        <p className="body-2 mb-2">PAYS</p>
                                                        <p className="body-3 muted">{item.nationality}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={`mt-4 ${sDashboard.widgetBlock}`}>
                                            <div className={sDashboard.widgetBody}>
                                                <div className="d-flex">
                                                    <img className={` mr-3 ${act.image}`} alt="..." src={money}
                                                    />

                                                    <div className="d-flex flex-column">
                                                        <p className="body-2 mb-2">Status Cotisation</p>
                                                        <p className="body-3 muted">  {item.is_up_to_date === 0 ?
                                                            <Label className="label label-success text-success">Cotisation
                                                                à jour</Label> :
                                                            <Label className="label label-danger text-danger">Cotisation
                                                                en retard
                                                                de {item.is_up_to_date} mois</Label>}
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className={"d-flex my-2 flex-column align-items-center"}

                                    >

                                        <Button className={"w-100 m-2 "} color={"success"}
                                            onClick={() => handleOpenContribution(item.contribution, item)}
                                        >Mettre à jour les cotisations</Button>
                                        <Button className={"w-100 m-2 "} color={"primary"}
                                            onClick={() => handleOpenNotification([item])}
                                        >
                                            Envoyer une Notification
                                        </Button>
                                    </div>
                                </div>
                            ) : <Empty text={"Aucun utilisateur trouvé"} />

                        }
                    </div>


                    <Paginator currentPage={currentPage} pagesCount={pagesCount}
                        setTablePage={setTablePage}
                    />


                </Widget>

            </Col>
        </Row>
    )
}

export default UserDataDisplay