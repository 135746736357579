import {createSlice} from "@reduxjs/toolkit";




const navigation = createSlice({
        name: 'navigation',
        initialState: {
            sidebarOpened: false,
// @ts-ignore

            activeItem: JSON.parse(localStorage.getItem('staticSidebar')) ? window.location.pathname : null,
            blur: false
        },
        reducers: {
            openSidebar: (state: NavigationState) => {
                state.sidebarOpened = true;
            },
            closeSidebar: (state: NavigationState) => {
                state.sidebarOpened = false;
            },
            changeActiveSidebarItem: (state: NavigationState, action: { payload: string }) => {
                state.activeItem = action.payload;
            },
            blurBackground: (state: NavigationState) => {
                state.blur = true;
            },
            unBlurBackground: (state: NavigationState) => {
                state.blur = false;
            }
        }
    }
)

export const {
    openSidebar,
    closeSidebar,
    changeActiveSidebarItem,
    blurBackground,
    unBlurBackground
} = navigation.actions;
export default navigation.reducer;