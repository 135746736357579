// -- React and related libs
import React from "react";
import {Switch, Route, withRouter, Redirect} from "react-router";
// -- Third Party Libs
import {Loader} from "../Loader/Loader";

// -- Custom Components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Dashboard from "../../pages/dashboard/Dashboard";
import Archives from "../../pages/archives/archives";


// -- Component Styles
import s from "./Layout.module.scss";
import {useSelector} from "react-redux";
import LocalService from "../../pages/LocalService/LocalService";
import RoleRequiredRoute from "../../security/RoleRequiredRoute";
import AdminRoute from "./Admin";
import Role from "../../security/Roles";
import {useGetNotificationQuery} from "../../services/functionnalityService";
import PharmacyGard from "../../pages/PharmacyGard/PharmacyGard";
import useGetLocation from "../../hooks/useGetLocation";

const User = ({user}: { user: User }) => {
    const {data: notifications, isFetching, error, refetch} = useGetNotificationQuery(user.id,
        {
            pollingInterval: 120 * 1000,
        }
    );

    const {loading, error: locationError, coords} = useGetLocation();
    const blur = useSelector((state: { navigation: NavigationState }) => state.navigation.blur)
    const pharmacy = function() {
        if (loading) return <Loader center={true}/>
        if (locationError) return  <PharmacyGard userLocation={null}/>
        console.log(coords)
        return <PharmacyGard userLocation={coords || null}/>
    }

    return (

        <div className={`${s.root} ${blur ? s.blur : ""}`}>
            <div className={s.wrap}>
                <Header user={user}
                        isFetchingNotification={isFetching}
                        notifications={notifications}
                        refetchNotification={refetch}
                        errorNotification={error}
                />
                <Sidebar user={user}
                         notificationCount={isFetching ? 0 : error ? 0 :
                             [...notifications.data].filter((key) => {
                                     return !key.is_read
                                 }
                             ).length}
                />
                <main className={s.content}>
                    <Switch>
                        <Route path="/user" exact render={() => <Redirect to="user/dashboard"/>}/>
                        <RoleRequiredRoute path="/user/admin" component={AdminRoute} role={Role.ADMIN}/>
                        <Route path="/user/dashboard" exact render={() => <Dashboard user={user}/>}/>
                        <Route path="/user/archives" exact render={() => <Archives />}/>
                        <Route path="/user/local_services" exact component={() => <LocalService/>}/>
                        <Route path="/user/pharmacy" exact
                               component={() => pharmacy()}/>
                        <Route path='*' exact render={() => <Redirect to="/error"/>}/>
                    </Switch>
                </main>
                <Footer/>
            </div>
        </div>
    );

}


export default withRouter(User);
