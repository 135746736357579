import nav from "./navigation";
import auth from "./auth";
import reg from "./register";
import {authApi} from "../services/authService";
import {functionalityApi} from "../services/functionnalityService";
import {activityApi} from "../services/activity";
import {userApi} from "../services/user";
import {localServicesApi} from "../services/localServices";
import {bourseApi} from "../services/bourse";
import {archiveApi} from "../services/archive";

export default {
    registration: reg,
    authentication: auth,
    navigation: nav,
    [authApi.reducerPath]: authApi.reducer,
    [functionalityApi.reducerPath]: functionalityApi.reducer,
    [activityApi.reducerPath]: activityApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [localServicesApi.reducerPath]: localServicesApi.reducer,
    [bourseApi.reducerPath]: bourseApi.reducer,
    [archiveApi.reducerPath]: archiveApi.reducer,
};