import {Col, Row} from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import act from "../../activity/activite.module.scss";
import ApexLineColumnChart from "../../activity/components/ApexLineColumnChart";
import React from "react";
import {useGetContributionSumQuery} from "../../../services/functionnalityService";
import {Loader} from "../../../components/Loader/Loader";
import {ErrorBox} from "../../../components/Alert/Alert";

function MensualChart() {
    const {
        data: contribution,
        isFetching,
        error
    } = useGetContributionSumQuery()
    const content = function () {
        if (isFetching) return <div style={
            {
                minHeight: "300px",
            }
        }><Loader center={true}/></div>
        else if (error) return <ErrorBox message={error.data.message}/>
        const {month_cotisations, total} = contribution.data
        return <React.Fragment>
            <div className={`headline-2 mb-3 ${act.TitleContainer}`}>
                <p>Somme des cotisations du mois actuelles</p>
                <p>MONTANT: <span className={act.somme}>  {month_cotisations} DH</span></p>
            </div>
            <ApexLineColumnChart contribution={total}/>
        </React.Fragment>
    }


    return (
        <Row className="gutter mb-4">
            <Col>
                <Widget className="widget-p-md">
                    {content()}
                </Widget>
            </Col>
        </Row>
    )
}

export default MensualChart;