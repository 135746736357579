import React from "react";

import whatsapp from "./icon/whatsapp.svg"
import maps from "./icon/maps.svg"
import tel from "./icon/tel.svg"
import {
    Col,
    Row,
    Button,

} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import {toast} from "react-toastify";

import s from "./Dashboard.module.scss";

import PropTypes from "prop-types";

import {useGetServicesQuery} from "../../services/localServices";
import {Loader} from "../../components/Loader/Loader";
import {ErrorBox} from "../../components/Alert/Alert";
import servicesIcon from "./services";
import Popup from "../../components/Notification/Popup";
import {ProgressBarLanguageProficiency} from "../../utilities/utilities";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const options = {
    autoClose: 2000,
    closeButton: false,
    hideProgressBar: false,

};


const LocalService = () => {
    const {data, isFetching, error} = useGetServicesQuery()

    const callText = isMobile ? "Appeler" : "Copier le numéro"

    const onCall = (phone) => {

        if (isMobile) {
            window.open(`tel:${phone}`, "_blank")

        } else {
            navigator.clipboard.writeText(phone).then(() => {
                toast(<Popup type={"success"} message={"Vérifiez votre presse papier"} withIcon/>, options)
            })
        }
    }
    if (isFetching) return <Loader center={true}/>
    if (error) return <ErrorBox message={error.data.message}/>
    return (
        <div>
            <Row>
                {
                    data["providers"].map(
                        service =>
                            <Col xs={12} md={6} xl={4} className="mt-4 mb-4 mt-lg-0
                            ">
                                <Widget className="widget-p-md justify-content-between">
                                    <div className="headline-2 text-center">
                                        <img className="img-fluid mr-3 rounded mb-2" alt="Image du prestataire"
                                             src={service.provider.picture_link}
                                             width="50" height="50"
                                        />
                                        <p>{service.provider.title} {service.provider.name}</p>
                                    </div>


                                    <div className={`my-4 ${s.widgetBlock}`}>
                                        <div className={`${s.widgetBody}`}>
                                            <div className="d-flex">
                                                <img className="img-fluid mr-3 rounded" alt="Icone associé au services"
                                                     src={service.type in servicesIcon ? servicesIcon[service.type] :
                                                         servicesIcon["default"]}
                                                     width="50" height="50"
                                                />
                                                <div className="d-flex flex-column overflow-auto" style={{
                                                    height: "100px"
                                                }}>
                                                    {service.description}
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className={"d-flex align-items-center justify-content-between my-4"}>
                                        <p className={"font-weight-bold"}>Français</p>
                                        <div className={"w-50"}>
                                            <ProgressBarLanguageProficiency proficiency={service.provider.proficiency}
                                                                            max="100"/>
                                        </div>
                                    </div>
                                      <div className={"mb-2 d-flex justify-content-center w-100"}>
                                        <Button
                                            color="primary"
                                            outline={true}
                                            className=" rounded-pill "
                                            onClick={() => {
                                                window.open(service.location, "_blank")
                                            }}
                                        >
                                            <div className="d-flex">
                                                <img src={maps} alt="..." width={20} height={20}/>
                                                <div className="ml-2 body-2">Trouver sur google Maps</div>
                                            </div>
                                        </Button>
                                    </div>
                                    <div className={"mb-2"}>
                                        <Button
                                            color="success"
                                            className="notification-btn rounded-pill "
                                            onClick={() => {

                                                window.open(`https://wa.me/${service.provider.whatsapp}`, "_blank")
                                            }}
                                        >
                                            <div className="d-flex">
                                                <img src={whatsapp} alt="..." width={20} height={20}/>
                                                <div className="ml-2 body-2">Whatsapp</div>
                                            </div>
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            color="info"
                                            className="notification-btn rounded-pill "
                                            onClick={() => onCall(
                                                service.provider.phone
                                            )}
                                        >
                                            <div className="d-flex">
                                                <img src={tel} alt="..." width={20} height={20}/>
                                                <div className="ml-2 body-2">{callText}</div>
                                            </div>
                                        </Button>
                                    </div>

                                </Widget>
                            </Col>
                    )
                }


            </Row>

        </div>
    )
}
LocalService.propTypes = {
    user: PropTypes.object.isRequired,
};

export default LocalService;
