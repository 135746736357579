// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {BASE_URL} from "../config";


export const activityApi = createApi({
    reducerPath: 'activityApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/Activities`,
        prepareHeaders: (headers, {getState}) => {
            // @ts-ignore
            const token = getState().authentication.userToken
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        },

    }),
    endpoints: (builder) => ({
        getAllActivities: builder.query({
            query: (participants: boolean = false) => ({
                url: ``,
                params: {
                    participants: participants
                },
                method: 'GET',
            }),
        }),
        createActivities: builder.mutation({
            query: (data) => ({
                url: ``,
                method: 'POST',
                body: data
            }),
        }),
        updateActivities: builder.mutation({
            query: ({activity_id, data}) => ({
                url: `/${activity_id}`,
                method: 'PATCH',
                body: data
            }),
        }),

        deleteActivities: builder.mutation({
            query: (activity_id) => ({
                url: `/${activity_id}`,
                method: 'DELETE',
            }),
        }),

        toggleUserSubscription: builder.mutation({
            query: ({id_activity, id_user}) => ({
                url: `/${id_activity}/Users/${id_user}`,
                method: 'POST',

            }),
        }),
    })
})

export const {
    useGetAllActivitiesQuery,
    useToggleUserSubscriptionMutation,
    useUpdateActivitiesMutation,
    useCreateActivitiesMutation,
    useDeleteActivitiesMutation,
} = activityApi