import Compress from "compress.js";
import JsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

const compress = new Compress();

export function putOnPreview(file, file_preview) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
        file_preview.src = event.target?.result;
    }
}

export const resizeFile = async function resizeImageFn(file, file_preview) {

    const resizedImage = await compress.compress([file], {
        size: 2, // the max size in MB, defaults to 2MB
        quality: 1, // the quality of the image, max is 1,
        maxWidth: 300, // the max width of the output image, defaults to 1920px
        maxHeight: 300, // the max height of the output image, defaults to 1920px
        resize: true // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0];
    const base64str = img.data
    const imgExt = img.ext
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    file_preview.src = URL.createObjectURL(resizedFile);
    return resizedFile;
}

export const exportToPDF = (headers, data, title) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new JsPDF(orientation, unit, size);

    doc.setFontSize(15);


    const content = {
        startY: 50,
        head: headers,
        body: data,
        theme: 'grid'
    };


    doc.text(title, marginLeft, 40);
    // @ts-ignore
    autoTable(doc, content)
    doc.save(`${title}.pdf`);
};
