import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import React from "react";
import pStyle from "./paginator.module.scss"

const Paginator = ({currentPage, setTablePage, pagesCount, className}) => {
    return (
        <Pagination className={`pagination-borderless overflow-auto ${pStyle.thinScrollbar} ${className}`}
                    aria-label="Page navigation example">
            <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                    onClick={e => setTablePage(e, currentPage - 1)}
                    previous
                    href="#top"
                />
            </PaginationItem>



            <div>

            </div>
            {[...Array(pagesCount)].map((page, i) =>
                <PaginationItem active={i === currentPage} key={i}>
                    <PaginationLink onClick={e => setTablePage(e, i)} href="#top">
                        {i + 1}
                    </PaginationLink>
                </PaginationItem>
            )}


            <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                    onClick={e => setTablePage(e, currentPage + 1)}
                    next
                    href="#top"
                />
            </PaginationItem>
        </Pagination>
    )
}

export default Paginator