import Widget from "../../Widget/Widget";
import {Loader} from "../../Loader/Loader";
import money from "../../../assets/money.svg";
import description from "../../../assets/description.svg";
import React, {useState} from "react";
import {Button} from "reactstrap";
import {useToggleUserSubscriptionMutation} from "../../../services/activity";
import {ErrorBox} from "../../Alert/Alert";
import {SucessBox} from "../../Alert/Sucess";


function Confirmation({activity, icon, user, setActivityToggled}: {
    activity: Activity | null,
    icon: string,
    user: User,
    setActivityToggled: Function
}) {
    const [toggleUserSubscription, {isLoading}] = useToggleUserSubscriptionMutation()
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [sucessMessage, setSucessMessage] = useState<string | null>(null)
    const toggleSubscription = (e) => {
        try {


            toggleUserSubscription({id_activity: activity?.id, id_user: user.id}).then(
                (res) => {

                    setSucessMessage("Inscription bien enrégistrée")
                    setErrorMessage(null)
                    setActivityToggled(true)
                }
            ).catch(
                (err) => {
                    setErrorMessage("Erreur lors de l'inscription")
                    setSucessMessage(null)
                    console.log(err)
                }
            )
        } catch (e) {
            // @ts-ignore
            setErrorMessage(e)
            setSucessMessage(null)
            console.log(e)
        }

    }
    return (
        <div>
            <Widget className="widget-p-md d-flex flex-column">
                <h4 className={" text-center my-3"}>{activity?.name}</h4>
                <div className="d-flex align-items-center my-2">

                    <img className="img-fluid mr-3 " alt={".."} src={icon} width={40}
                         height={40}/>
                    <div className="d-flex flex-column">

                        <p className="body-3 muted"> {activity?.day} à {activity?.time}</p>
                    </div>
                </div>
                <div className="d-flex align-items-center my-2">

                    <img className="img-fluid mr-3 " alt={".."} src={description} width={40}
                         height={40}/>
                    <div className="d-flex flex-column">

                        <p className="body-3 muted"> {activity?.description}</p>
                    </div>
                </div>
                <div className="d-flex align-items-center my-2">

                    <img className="img-fluid mr-3 " alt={".."} src={money} width={40}
                         height={40}/>
                    <div className="d-flex flex-column">

                        <p className="body-3 muted"> {activity?.price === 0 ?
                            <strong>Gratuit</strong> : `${activity?.price} DH`}</p>
                    </div>
                </div>
                <div className={"w-100 d-flex align-items-center my-3"}>
                    <Button className={"w-100 mt-2 mr-2"} color="primary" onClick={toggleSubscription}
                            disabled={isLoading || sucessMessage !== null
                    }>

                        Participer</Button>
                    {isLoading && <Loader/>}

                </div>
                {errorMessage !== null && <div className={"mt-3"}>
                    <ErrorBox message={`${errorMessage}`}/></div>}
                {sucessMessage !== null && <div className={"mt-3"}>
                    <SucessBox message={`${sucessMessage}`}/>

                </div>}

            </Widget>

        </div>
    );
}

export default Confirmation


