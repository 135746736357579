import React from "react";
import ApexCharts from "react-apexcharts";
import {useGetNationalityCountQuery} from "../../services/functionnalityService";
import {Loader} from "../Loader/Loader";
import tinycolor from 'tinycolor2';
import * as palette from "../../assets/palettes";
import {InfoBox} from "../Alert/Info";
import {ErrorBox} from "../Alert/Alert";


const chartSize = {
    width: 300,
    height: 300,
}

function createColorPicker(palette: string[]): () => string {
    const paletteCopy = palette.slice(); // Crée une copie de la palette

    return function getRandomColorFromPalette(): string {
        if (paletteCopy.length === 0) {
            paletteCopy.push(...palette);
        }

        const randomIndex: number = Math.floor(Math.random() * paletteCopy.length);
        const color = tinycolor(paletteCopy[randomIndex]);


        paletteCopy.splice(randomIndex, 1); // Supprime la couleur choisie de la copie de la palette

        return color.toHexString();
    };
}

const getUniqueColor: () => string = createColorPicker(palette.custom);


function chartSettings(stats: { [key: string]: number }) {
    const series: number[] = Object.values(stats);
    const labels: string[] = Object.keys(stats);

    const colors: string[] = series.map(() => getUniqueColor());
    return {
        colors: colors,
        chart: {
            height: "100%",
            width: "100%",
            type: "pie",
            toolbar: {
                show: false,
            },
        },
        stroke: {

            width: 0.5,

        },
        yaxis: {
            show: false,
        },
        labels: labels,
        series: series,
        legend: {
            position: "bottom",
            /* justify-content: center; */


        },
        /* Margin x */

        plotOptions: {
            pie: {
                pie: {
                    size: '50%',

                },
            },
        },


    };
}

export default function CommunityChart(props: { className: string }) {
    const {className} = props;
    const {data, isFetching, error} = useGetNationalityCountQuery('');
    const content = function () {


        if (isFetching) return <div className="d-flex  justify-content-between widget-p-md ">
            <div className="headline-3   text-center w-100 ">
                <Loader center={true}/>
            </div>
        </div>
        if (error) return <ErrorBox message={"Une erreur est survenue"}/>
        const stats = data.data as { [key: string]: number }
        
        const chartOptions = chartSettings(stats);
        // @ts-ignore
        return (<React.Fragment>

            <div className={"d-none d-md-block"}>
                <InfoBox message={"ℹ️ Survolez un angle pour voir son effectif"}/>
            </div>
            <div className={"d-block d-md-none mx-4"}>
                <InfoBox message={"ℹ️ Maintenez un angle pour voir son effectif"}/>
            </div>

            <ApexCharts
                // @ts-ignore

                options={chartOptions}
                series={chartOptions.series}
                type="pie"
                height={chartSize.height + 50}
            />
        </React.Fragment>)
    }

    return (
        <div className={className}>
            <div className="d-flex  justify-content-between widget-p-md ">
                <div className="headline-3   text-center w-100 ">
                    Effectif par communauté
                </div>
            </div>
            {content()}
        </div>

    )
}
