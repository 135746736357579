// -- React and related libs
import React from 'react';
import {render} from 'react-dom';

// -- Redux
import {configureStore} from '@reduxjs/toolkit'
import {Provider} from 'react-redux';


// -- App
import App from './App';

// -- Service Worker
import {register} from './serviceWorker';
import reducers from "./slices";
import {authApi} from "./services/authService";
import {functionalityApi} from "./services/functionnalityService";
import LogRocket from 'logrocket';
import {activityApi} from "./services/activity";
import {IS_PRODUCTION} from "./config";
import {userApi} from "./services/user";
import {localServicesApi} from "./services/localServices";
import {bourseApi} from "./services/bourse";
import {archiveApi} from "./services/archive";

const isManager = localStorage.getItem('isManager') === 'true';
if (!isManager && IS_PRODUCTION) {
    LogRocket.init('cmv0ib/cesam-website');
}
// -- Data Store
const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authApi.middleware)
            .concat(functionalityApi.middleware)
            .concat(activityApi.middleware)
            .concat(userApi.middleware)
            .concat(localServicesApi.middleware)
            .concat(bourseApi.middleware)
            .concat(archiveApi.middleware)
})


render(
    <Provider store={store}>
        <App/>

    </Provider>,
    document.getElementById('root')
);

register();

