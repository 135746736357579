const palette: string[] = [
    "#FF5733",
    "#33FF57",
    "#5733FF",
    "#FF3380",
    "#33FFF5",
    // Ajoutez d'autres couleurs à votre palette
];
const darkPalette: string[] = [
    "#264653", // Dark Cyan
    "#2a9d8f", // Persian Green
    "#e76f51", // Burnt Sienna
    "#e9c46a", // Orange Yellow Crayola
    "#3a0ca3", // Sapphire Blue
    "#f72585", // Amaranth Pink
    "#7209b7", // Blue Violet Crayola
    "#4361ee", // Blue Jeans
    "#d00000", // Luminous Red
    "#9d0208", // Dark Red
    "#6a040f", // Wine
    "#370617", // Blackberry
    "#03071e", // Oxford Blue
    "#480ca8", // Blue Sapphire
    "#3f37c9", // Medium Blue
    "#4895ef", // Blue Crayola
    "#4ea8de", // United Nations Blue
    "#48cae4", // Sky Blue
    "#00b4d8", // Pacific Blue
];

const custom: string[] = [
    "#4F0000",
    "#303437",
    "#AE8B70",
    "#7A2C2C",
    "#C1B1B6",
    "#47525A",
    "#8C6B5A",
    "#A53838",
    "#5B6576",
    "#C49D90",
    "#D16A6A",
    "#9DA3AB",
    "#D5B8AC",
    "#264653"
]

export {palette, darkPalette, custom};