import {createAsyncThunk} from '@reduxjs/toolkit'
import {BASE_URL} from "../config";
import axios from "axios";


function register(user_data: UserRegister) {
    const option = {
        headers: {
            "Content-Type": "application/json",
        }
    }
    const body = {
        name: user_data.name,
        surname: user_data.surname,
        mail: user_data.mail,
        password: user_data.password,
    }
    const url = `${BASE_URL}/Users`
    return axios.post(url, body, option)
        .then(({data}) => {
            return data
        })


}
export const registerUser = createAsyncThunk(
    'auth/register',
    async ({name, surname, mail, password}: UserRegister, {rejectWithValue}) => {
        try {

            await register({name: name, surname: surname, mail: mail, password: password})
        } catch (error: any) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)