import Widget from "../../../components/Widget/Widget";
import {Button, Col, FormGroup, Row} from "reactstrap";
import {ErrorBox} from "../../../components/Alert/Alert";
import {SucessBox} from "../../../components/Alert/Sucess";
import {Loader} from "../../../components/Loader/Loader";

function getDate(date) {
    const dateObj = new Date(date)
    const day = dateObj.getDate()
    const month = dateObj.getMonth() + 1
    const year = dateObj.getFullYear()
    return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`
}

function getTime(date) {
    const dateObj = new Date(date)
    const hours = dateObj.getHours()
    const minutes = dateObj.getMinutes()
    return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`
}

function EventForm({
                       title,
                       handleSubmit,
                       activity,
                       mode,
                       submitForm,
                       errorMessage,
                       successMessage,
                       register,
                       isLoading,
                        instruction
                   }) {

    return (
        <div>
            <Widget className="widget-p-md ">

                <form onSubmit={handleSubmit(submitForm)}>
                    <h3 className={"text-center"}>{title}</h3>

                    <FormGroup className=" mr-3 mb-4">
                        <Row>
                            <Col xs={9} className={"mt-3"}>
                                <p className={"body-2 mt-2 text-center"}>Nom</p>
                                <input
                                    className="form-control  input-transparent mt-2 pl-3"
                                    type="text"
                                    {...register('name')}
                                    required
                                    defaultValue={mode === "edit" ? activity.name : ""}
                                />
                            </Col>
                            <Col xs={3} className={"mt-3"}>
                                <p className={"body-2 mt-2 text-center"}>Actif</p>
                                <input
                                    className={`form-control mt-2 pl-3`}
                                    type="checkbox"
                                    {...register('actif')}
                                    defaultChecked={mode === "edit" ? activity.actif : true}
                                />
                            </Col>

                            <Col xs={12} className={"mt-3 "}>
                                <p className={"body-2 text-center mt-2"}>Description</p>
                                <textarea className={"form-control"}

                                          style={{resize: "none"}}
                                          {...register('description')}
                                          defaultValue={mode === "edit" ? activity.description : ""}
                                          required/>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} lg={6} className={"mt-3 "}>
                                <p className={"body-2 text-center mt-2"}>Date</p>
                                <input
                                    className="form-control input-transparent mt-2 pl-3"
                                    type="date"
                                    required


                                    {...register('date')}


                                    defaultValue={mode === "edit" ? getDate(activity.date).toString() : ""}
                                />
                            </Col>
                            <Col xs={12} lg={6} className={"mt-3 "}>
                                <p className={"body-2 text-center mt-2"}>Heure</p>
                                <input
                                    className="form-control input-transparent mt-2 pl-3"
                                    type="time"
                                    required

                                    {...register('time')}

                                    defaultValue={mode === "edit" ? getTime(activity.date).toString() : ""}
                                />
                            </Col>

                            <Col xs={12} className={"mt-3 "}>
                                <p className={"body-2 text-center mt-2"}>Prix</p>
                                <input
                                    className="form-control input-transparent mt-2 pl-3"
                                    type="number"

                                    {...register('price')}
                                    min={0}
                                    defaultValue={mode === "edit" ? activity.price : 0}
                                />
                            </Col>
                        </Row>

                    </FormGroup>
                    <FormGroup className=" mr-3">
                        {errorMessage !== null &&
                            <div className={"mt-3"}>
                                <ErrorBox message={`${errorMessage}`}/>

                            </div>}
                        {successMessage !== null &&
                            <div className={"mt-3"}>
                                <SucessBox message={`${successMessage}`}/>
                            </div>}

                        <Row>
                            <Col xs={10} className={"d-flex align-items-center justify-content-center"}>
                                <Button className="rounded-pill my-3  " type="submit"
                                        color="secondary-red">{instruction}</Button>
                            </Col>
                            {isLoading && <Col><Loader/></Col>}

                        </Row>

                    </FormGroup>
                </form>


            </Widget>

        </div>
    )
}

export default EventForm;