import Widget from "../Widget/Widget";

import React from "react";
import remarkGfm from 'remark-gfm'
import ReactMarkdown from "react-markdown";
import s from "./Notification.module.scss"
function Notification({notification, icon}) {

    return (
        <div>
            <Widget className="widget-p-md d-flex flex-column align-items-center">
                <h4 className={" text-center mb-2"}>{notification.title}</h4>
                <div className="d-flex align-items-center mt-3 flex-column flex-md-row">

                    <img className="img-fluid mr-3 " alt={".."} src={icon} width={40}
                         height={40}/>
                    <div className="d-flex mt-3 flex-column">

                        <p className="body-3">
                            <ReactMarkdown remarkPlugins={[remarkGfm]} children={notification.content}

                                           className={s.notificationContent}
                            />



                        </p>
                    </div>
                </div>


            </Widget>

        </div>
    );
}

export default Notification;


