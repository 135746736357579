import sDashboard from "../../pages/dashboard/Dashboard.module.scss";
import sAmci from "./Tables.module.scss"
import {IconButton} from "@material-ui/core";
import {useMemo, useState} from "react";
import moment from "moment/moment";

const status = {
    PAST: {
        text: "Passée",
        color: "red"
    },
    NEXT: {
        text: "Paiement Imminent",
        color: "green"
    },
    TO_COME: {
        text: "A venir",
        color: "blue"
    }
}

function getWithdrawDate(date) {
    return moment(date).format('dddd DD MMMM YYYY ')
}

function getPeriod(start, end) {
    return `${moment(start).format('MMMM')}-${moment(end).format('MMMM YYYY')}`
}

export default function Planning(props: { plannings: PlanningInterface[], svgImage: Bourse }) {
    const {plannings, svgImage} = props

    const data = useMemo(() => [...plannings].sort((a, b) => {
        return moment(a.start_date).diff(moment(b.start_date))
    }), [plannings])
    const [currentIndex, setCurrentIndex] = useState(
        data.findIndex(p => p.status === "NEXT") == -1 ? plannings.length - 1 : data.findIndex(p => p.status === "NEXT")
    )

    const nextIndex = () => {
        if (currentIndex < data.length - 1) {
            setCurrentIndex(currentIndex + 1)
        }
    };

    const prevIndex = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1)
        }
    };
    return (
        <div className={`mt-4 ${sDashboard.widgetBlock}`}>

            <div className={`${sDashboard.widgetBody} d-flex`}>


                <img className="img-fluid mr-3 "
                     alt="..."
                     src={svgImage["planning"]}
                     width={40}
                     height={40}
                />

                <IconButton onClick={prevIndex} className={`${currentIndex > 0 && sAmci.enabled} ${sAmci.navigation}`}>
                    <i className={`fa fa-arrow-left`}> </i>
                </IconButton>
                <div className="d-flex px-2 mx-2 flex-column">


                    <div className="d-flex flex-column">
                        <p className="body-2 mb-1 text-center" style={
                            {
                                color: status[data[currentIndex]['status']]['color']
                            }
                        }
                        >{status[data[currentIndex]['status']]['text']}</p>
                        <p className="body-3 mb-1 muted text-center">{getPeriod(data[currentIndex]['start_date'],
                            data[currentIndex]['end_date'])}</p>
                        <p className="body-3  text-center">Date de
                            retrait: {getWithdrawDate(data[currentIndex]['withdraw_date'])}</p>
                    </div>
                </div>
                <IconButton onClick={nextIndex}
                            className={`${currentIndex < data.length - 1 && sAmci.enabled} ${sAmci.navigation}`}>
                    <i className={`fa fa-arrow-right`}> </i>
                </IconButton>

            </div>
        </div>
    )
}