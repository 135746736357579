import React, {useEffect, useState} from "react";
import {withRouter, Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    FormText,

} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import Footer from "../../components/Footer/Footer.tsx";
import {useForm} from 'react-hook-form'
import loginImage from "../../assets/registerImage.svg";
import SofiaLogo from "../../components/Icons/CesamLogo.js";
import {registerUser} from "../../thunks/register";
import {ErrorBox} from "../../components/Alert/Alert";
import {Loader} from "../../components/Loader/Loader";
import {userLogin} from "../../thunks/login";


const Register = () => {


    const {loading, userToken, error, success} = useSelector(
        (state) => {
            return {
                loading: state.registration.loading,
                userToken: state.registration.userToken,
                error: state.registration.error,
                success: state.registration.success
            }
        }
    )
    const [credentials, setCredentials] = useState()
    const dispatch = useDispatch()

    const history = useHistory()
    const {register, handleSubmit} = useForm();

    useEffect(() => {
        // login user if registration was successful
        if (success) {
            dispatch(userLogin(credentials))
            history.push('/user')
        }
        // redirect authenticated user to profile screen
        if (userToken) {
            history.push('/user')
        }
    }, [history, userToken, success])
    const submitForm = (data) => {
        dispatch(registerUser(data))
        setCredentials({
            mail: data.mail,
            password: data.password
        })
    }
    return (
        <div className="auth-page">
            <Container className="col-12">
                <Row className="d-flex align-items-center">
                    <Col xs={12} lg={6} className="left-column">
                        <Widget className="widget-auth widget-p-lg">
                            <div className="d-flex align-items-center justify-content-between py-3">
                                <div className="logo-block">
                                    <SofiaLogo/>
                                    <p className="mb-0">Cesam Khouribga</p>
                                </div>
                            </div>
                            <div className="auth-info my-2">
                                <p className="mb-0">
                                    ⚠️
                                    Si vous êtes <b>Cesamien</b> ne vous inscrivez pas par ce formulaire, contactez
                                    plutôt un administrateur
                                </p>
                            </div>

                            <form onSubmit={handleSubmit(submitForm)}>
                                {error && <ErrorBox message={error}></ErrorBox>}
                                <div className={"d-flex my-4"}>
                                    <FormGroup className=" mr-3">
                                        <FormText>Nom</FormText>
                                        <input
                                            className="form-control input-transparent mt-2 pl-3"
                                            type="text"
                                            required
                                            {...register('name')}
                                            placeholder="Votre nom"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormText>Prénom</FormText>
                                        <input
                                            className="form-control input-transparent mt-2 pl-3"
                                            type="text"
                                            required
                                            {...register('surname')}

                                            placeholder="Votre prenom"
                                        />
                                    </FormGroup>
                                </div>

                                <FormGroup>
                                    <FormText>Email</FormText>
                                    <input
                                        className="form-control input-transparent mt-2 pl-3"
                                        type="email"
                                        required
                                        {...register('mail')}

                                        placeholder="Votre adresse mail"
                                    />
                                </FormGroup>
                                <FormGroup className="my-3">
                                    <FormText>Mot de passe</FormText>
                                    <input
                                        className="form-control input-transparent mt-2 pl-3"

                                        type="password"
                                        required
                                        {...register('password')}

                                        placeholder="Votre mot de passe"
                                    />
                                </FormGroup>
                                <div className="bg-widget d-flex justify-content-center">
                                    <Button className="rounded-pill my-3" type="submit" disabled={loading}
                                            color="secondary-red">S'inscrire</Button>
                                </div>
                                {loading && <Loader/>}
                                <p className="dividing-line my-3">&#8195;Ou&#8195;</p>

                                <Link to="/login">
                                    <center>Je possède déja un compte</center>
                                </Link>
                            </form>
                        </Widget>
                    </Col>
                    <Col xs={0} lg={6} className="right-column">
                        <div>
                            <img src={loginImage} alt="Error page"/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>
    )
}


export default withRouter(Register);
