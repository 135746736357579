import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {BASE_URL} from "../config";


export const archiveApi = createApi({
    reducerPath: 'archiveApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/archives`,
        prepareHeaders: (headers, {getState}) => {
            // @ts-ignore
            const token = getState().authentication.userToken
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        },

    }),
    endpoints: (builder) => ({
        travelTree: builder.query({
            query: (path: string) => ({
                url: `/travel_tree`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    path: path
                }
            }),

        }),
    })
})

export const {
    useTravelTreeQuery,
} = archiveApi;