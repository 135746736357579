// authActions.js
import {createAsyncThunk} from "@reduxjs/toolkit";
import {BASE_URL} from "../config";
import axios from "axios";

function login(mail, password) {
    const option = {
        headers: {
            "Content-Type": "application/json",
        }
    }
    const body = {
        mail: mail,
        password: password
    }
    const url = `${BASE_URL}/auth/login`
    return axios.post(url, body, option)
        .then(({data}) => {
            return data
        })
}

export const userLogin = createAsyncThunk(
    'auth/login',
    async ({mail, password}: { mail: string, password: string }, {rejectWithValue}) => {
        try {
            // configure header's Content-Type as JSON

            const data = await login(mail, password)
            // store user's token in local storage
            localStorage.setItem('userToken', data["auth_token"])
            return data
        } catch (error: any) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)