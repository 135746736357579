import {useEffect, useState} from "react";

export default function useGetLocation():{
    coords: Coordinates | null,
    error: string | null,
    loading: boolean
} {
    const [coords, setCoords] = useState<Coordinates| null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        if (!("geolocation" in navigator)) {
            return;
        }
        const handleSuccess = (position) => {

            setCoords({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });
            setLoading(false);
        };
        const handleError = (error) => {
            console.log("Error getting location!");
            console.log(error);
            setError(error.message);
            setLoading(false);
        }
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
        };
        navigator.geolocation.getCurrentPosition(handleSuccess, handleError, options);
    }, [])

    return {coords, error, loading}
}