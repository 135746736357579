import defaultCountry from "../components/Amci/icons/country.svg";
import countries from "./country";
import {Progress} from "reactstrap";
import moment from "moment/moment";
import {registerUser} from "../thunks/register";


const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const highlightSearchTerm = (text, searchTerm, color = "#516091", textColor = "white") => {
    if (searchTerm === "") {
        return text;
    }
    const regexStr = "(" + escapeRegExp(searchTerm.trim()) + ")";
    const regex = new RegExp(regexStr, "gi");
    const parts = text.split(regex);
    return (
        <>
            {parts.map((part, index) =>
                    regex.test(part) ? (
                        <span key={index} style={{
                            backgroundColor: color,
                            borderRadius: "5px",
                            color: textColor,
                        }}>
            {part}
          </span>
                    ) : (
                        <span key={index}>{part}</span>
                    )
            )}
        </>
    );
};

export function flagRetriever(country) {
    return country && country.toLowerCase() in countries ?
        `https://flagsapi.com/${countries[country.toLowerCase()]}/flat/64.png` : defaultCountry
}

export function formatDate(date, format = 'dddd DD MMMM YYYY à HH:mm') {
    /* Capitalize the first letter of the day */
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    return capitalize(moment(date).format('dddd DD MMMM YYYY à HH:mm'))
}


const ENTRY_LEVEL = 20;
const INTERMEDIATE_LEVEL = 40;
const ADVANCED_LEVEL = 60;
const PROFICIENT_LEVEL = 80;
const NATIVE_LEVEL = 100;

export function ProgressBarLanguageProficiency({proficiency, ...props}) {

    if (proficiency < ENTRY_LEVEL)
        return <Progress {...props} color="danger" value={proficiency}/>
    else if (proficiency < INTERMEDIATE_LEVEL)
        return <Progress {...props} color="warning" value={proficiency}/>
    else if (proficiency < ADVANCED_LEVEL)
        return <Progress {...props} color="info" value={proficiency}/>
    else if (proficiency < PROFICIENT_LEVEL)
        return <Progress {...props} color="primary" value={proficiency}/>
    else if (proficiency < NATIVE_LEVEL)
        return <Progress {...props} color="success" value={proficiency}/>
    else
        return <Progress {...props} color="success" value={proficiency}/>
}

function getUserLocation(): { latitude: number; longitude: number; } | null{
    if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
            // Success callback function
            (position) => {
                // Get the user's latitude and longitude coordinates
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;

                // Do something with the location data, e.g. display on a map
            },
            // Error callback function
            (error) => {
                return null
            }
        );

    }
    return null;
}