import {Col, Row} from "reactstrap";
import Widget from "../../../components/Widget/Widget";

import React, {useState} from "react";

import AddNotification from "./AddNotification";
import ContributionCesamien from "./ContributionCesamien";
import {blurBackground, unBlurBackground} from "../../../slices/navigation";
import {useDispatch} from "react-redux";
import {useGetAllUsersQuery} from "../../../services/user";
import {Loader} from "../../../components/Loader/Loader";
import {ErrorBox} from "../../../components/Alert/Alert";

import ScrollableModal from "../../../components/ScrollableModal/ScrollableModal";
import UserDataDisplay from "./UserDataDisplay";

function UserTable() {
    const [openNotification, setOpenNotification] = React.useState(false);
    const dispatch = useDispatch();
    const [selectedCesamien, setSelectedCesamien] = useState({})
    const [selectedCesamiens, setSelectedCesamiens] = useState([])
    const [selectedContribution, setSelectedContribution] = useState({})
    const [openContribution, setOpenContribution] = React.useState(false);
    const [mustRefetch, setMustRefetch] = useState(false)

    const {
        data,
        isFetching,
        error,
        refetch
    } = useGetAllUsersQuery()

    const handleCloseNotification = () => {
        setOpenNotification(false);
        dispatch(unBlurBackground())
        if (mustRefetch) {
            setMustRefetch(false)
            refetch()
        }
    };
    const handleOpenNotification = (cesamien) => {
        setSelectedCesamiens(cesamien)
        setOpenNotification(true);
        dispatch(blurBackground())

    };

    const handleCloseContribution = () => {
        setOpenContribution(false);
        dispatch(unBlurBackground())
    };
    const handleOpenContribution = (contribution, cesamien) => {
        setSelectedContribution(contribution)
        setSelectedCesamien(cesamien)
        setOpenContribution(true);
        dispatch(blurBackground())
    };

    const handleOpenCreation = () => {
        /* Open the new tab */
        window.open("https://cesam-register.netlify.app/index.html?isAdmin=true", "_blank");
    };

    if (isFetching)
        return <Row className="gutter mb-4">
            <Col>

                <Widget>
                    <Loader center={true}/>
                </Widget>
            </Col>
        </Row>
    if (error) {
        return <ErrorBox message={error.data.message}/>
    }


    return (
        <React.Fragment>
            <ScrollableModal
                onClose={handleCloseNotification}
                open={openNotification}
            >
                <AddNotification cesamiens={selectedCesamiens} setMustRefetch={setMustRefetch}
                />
            </ScrollableModal>

            <ScrollableModal
                onClose={handleCloseContribution}
                open={openContribution}
            >
                <ContributionCesamien contribution={selectedContribution} cesamien={selectedCesamien}
                                      setMustRefetch={setMustRefetch}
                />
            </ScrollableModal>
            <UserDataDisplay data={[...data.data]} handleOpenContribution={handleOpenContribution}
                             handleOpenNotification={handleOpenNotification} handleOpenCreation={handleOpenCreation}/>
        </React.Fragment>

    )
}

export default UserTable