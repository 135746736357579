import act from "../../activite.module.scss";
import {DropdownItem, DropdownMenu, DropdownToggle, Label, Table, UncontrolledDropdown} from "reactstrap";
import {v4 as uuidv4} from "uuid";

import Paginator from "../../../Cesamiens/components/Paginator";
import Widget from "../../../../components/Widget/Widget";
import {useState} from "react";
import moment from "moment";
import {SucessBox} from "../../../../components/Alert/Sucess";
import {ErrorBox} from "../../../../components/Alert/Alert";
import Empty from "../../../../components/Empty/Empty";
import {IconButton} from "@material-ui/core";
import {exportToPDF} from "../../../../utilities/file";
import s from "../../../../components/Header/Header.module.scss";


function getDate(date) {
    return moment(date).format('DD/MM/YYYY')
}

function getTime(date) {

    return moment(date).format('HH:mm')
}

const fieldFunction = {
    "registration_date": (item) => `${getDate(item["registration_date"])} à ${getTime(item["registration_date"])}`,
    "is_up_to_date": (item) => item.Type === "cesamien" || item.Type === "admin" ?
        (item.is_up_to_date === 0 ?
            "Cotisation à jour" :
            `Cotisation en retard de ${item.is_up_to_date} mois`) : "Non cesamien"
}

const friendlyName = {
    "Date de remplissage": "registration_date",
    "Statut Cotisation": "is_up_to_date",
    "Nom": "name",
    "Prenom": "surname"
}

function friendlyNameResolver(data, key) {

    if (Object.keys(friendlyName).includes(key)) return friendlyName[key]
    else return key
}


export default function ListUser({data}) {
    /* Flatten users */
    const users = data.map(user => {
        const newUser = {...user, ...user.user}
        delete newUser.user;
        return newUser;
    })

    const base_headers = ["Date de remplissage", "Nom", "Prenom", "Statut Cotisation"]
    const allFields = [...base_headers,
        ...Object.keys(users[0]).filter(el => !([...Object.values(friendlyName)].includes(el)))]


    const [currentPage, setCurrentPage] = useState(0);
    const [selectedFields, setSelectedFields] = useState([...base_headers])
    const pageSize = 8;
    const pagesCount = Math.ceil(users.length / pageSize);
    const setTablePage = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
    }

    const exportPdf = () => {
        const data = users.map(user =>
            selectedFields.map(field => {
                const friendName = friendlyNameResolver(user, field)
                if (friendName in fieldFunction) return fieldFunction[friendName](user)
                else return user[friendName]
            }))
        exportToPDF([[...selectedFields]], data, "Liste des participants")
    }

    return (
        <Widget className={"p-3 h-100"}>


            <div className="widget-table-overflow">
                <div className={"d-flex"}>
                    <IconButton onClick={exportPdf}>
                        <i className={`fa fa-print fa-2x text-dark`}/>
                    </IconButton>
                    <UncontrolledDropdown className={"d-flex justify-content-end "}>
                        <DropdownToggle caret>
                            Champs à imprimer
                        </DropdownToggle>
                        <DropdownMenu caret>
                            <DropdownItem toggle={false}>
                                {
                                    allFields.map(title =>
                                        <label className={"d-flex my-2 justify-content-between"}>
                                            <input type="checkbox"
                                                   checked={selectedFields.includes(title)}
                                                   onChange={e => {
                                                       if (e.target.checked) {
                                                           setSelectedFields([...selectedFields, title])
                                                       } else {
                                                           setSelectedFields(selectedFields.filter(item => item !== title))
                                                       }
                                                   }}
                                            />
                                            <p>{title}</p>
                                        </label>
                                    )
                                }

                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>

                <Table className={`table-striped table-borderless table-hover ${act.statesTable}`}
                       responsive>
                    <thead>
                        <tr>

                            {base_headers.map((item, index) => (
                                <th key={uuidv4()} className="text-dark">
                                    <Label className="mt-2">{item}</Label>
                                </th>
                            ))}

                        </tr>
                    </thead>
                    <tbody>
                        {[...users]
                            .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                            .sort((a, b) => new Date(a.registration_date) - new Date(b.registration_date))
                            .map(item => (
                                <tr key={uuidv4()}>
                                    <td className="w-25">
                                        {getDate(item.registration_date)} à {getTime(item.registration_date)}
                                    </td>

                                    <td className="w-25">{item.name}</td>
                                    <td className="w-25">{item.surname}</td>
                                    <td className="w-25">
                                        {item.Type === "cesamien" || item.Type === "admin" ?
                                            item.is_up_to_date === 0 ?
                                                <SucessBox message={"Cotisation à jour"}/> :
                                                <ErrorBox message={`Cotisation en retard
                                                de ${item.is_up_to_date} mois`}/> :
                                            <Empty text={"Non cesamien"} style={"text-dark"}/>
                                        }

                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
                <Paginator currentPage={currentPage} pagesCount={pagesCount} className={"mt-2"}
                           setTablePage={setTablePage}/>
            </div>

        </Widget>
    )
}