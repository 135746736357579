// -- React and related libs
import React from "react";
import {Switch, Route, withRouter, Redirect} from "react-router";


import Cesamien from "../../pages/Cesamiens/Cesamiens";
import Activite from "../../pages/activity/activite";

// -- Component Styles
import s from "./Layout.module.scss";
import PropTypes from "prop-types";

const Admin = ({user}: { user: User }) => {

    return (

        <main className={s.content}>
            <Switch>
                <Route path="/user/admin" exact render={() => <Redirect to="/admin/Cesamiens"/>}/>

                <Route path="/user/admin/Cesamiens" exact component={Cesamien}/>
                <Route path="/user/admin/Activite" exact render={() => <Activite />}/>
                <Route path='*' exact render={() => <Redirect to="/error"/>}/>
            </Switch>
        </main>
    );

}


Admin.propTypes = {
    user: PropTypes.object.isRequired,
}
export default withRouter(Admin);
