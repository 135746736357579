import React, {useState} from "react";
import {useForm} from "react-hook-form";
import EventForm from "../eventForm";
import {useCreateActivitiesMutation} from "../../../../services/activity";
import moment from "moment/moment";


export default function Creation({setActivityEdited: setActivityCreated}) {
    const {register, handleSubmit} = useForm();
    const [createActivities, {isLoading}] = useCreateActivitiesMutation()
    const [errorMessage, setErrorMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)

    const submitForm = (data) => {
         const formData = {
            ...data,
            date: moment(data["date"] + "T" + data["time"]).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        }


        try {

            createActivities(formData)
                .unwrap()
                .then(() => {
                    setSuccessMessage("Les modifications ont été effectuées avec succès")
                    setErrorMessage(null)
                    setActivityCreated(true)
                })
                .catch((e) => {
                    console.log(e)

                    setErrorMessage(e.message)
                })
        } catch (e) {
            console.log(e)
            setErrorMessage(e.message)
            setSuccessMessage(null)
        }

    }

    return (
        <EventForm
            title={"Créer une activité"}
            handleSubmit={handleSubmit}
            mode={"creation"}
            submitForm={submitForm}
            errorMessage={errorMessage}
            successMessage={successMessage}
            register={register}
            isLoading={isLoading}
            instruction={"Créer une activité"}
        />
    )
}