import React, {useEffect} from "react";
import {withRouter, Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    FormText,

} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import Footer from "../../components/Footer/Footer";

import loginImage from "../../assets/loginImage.svg";
import SofiaLogo from "../../components/Icons/CesamLogo.js";
import {useForm} from "react-hook-form";
import {userLogin} from "../../thunks/login";
import {ErrorBox} from "../../components/Alert/Alert";
import {Loader} from "../../components/Loader/Loader";
import {IconButton} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import s from "./style.module.scss"
import {blurBackground, unBlurBackground} from "../../slices/navigation";
import AddNotification from "../Cesamiens/components/AddNotification";
import ScrollableModal from "../../components/ScrollableModal/ScrollableModal";
import {Info} from "@material-ui/icons";
import {InfoBox} from "../../components/Alert/Info";

const Login = () => {
    const [openWarning, setOpenWarning] = React.useState(false);

    const {loading, userToken, error, success} = useSelector((state) => {

        return {
            loading: state.authentication.loading,
            error: state.authentication.error,
            userToken: state.authentication.userToken,
            success: state.authentication.success
        };
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const dispatch = useDispatch()

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleOpenWarning = () => {
        setOpenWarning(true)
        dispatch(blurBackground())

    };
    const handleCloseWarning = () => {
        setOpenWarning(false)
        dispatch(unBlurBackground())
    };
    const history = useHistory()
    useEffect(() => {
        if (userToken && success) {
            history.push('/user')
        }
    }, [history, userToken])

    const {register, handleSubmit} = useForm()

    const submitForm = (data) => {
        dispatch(userLogin(data))
    }
    return (
        <>
            <ScrollableModal
                onClose={handleCloseWarning}
                open={openWarning}
            >
                <Widget className="widget-p-md ">
                    <div className="d-flex align-items-center justify-content-between py-3">

                         <InfoBox
                    message={"Cette fonctionnalité n'est pas encore disponible, si vous êtes un " +
                        "cesamien veuillez contacter un administrateur pour procéder à votre inscription"}/>
                    </div>

                </Widget>

            </ScrollableModal>
            <div className="auth-page">
                <Container className="col-12">
                    <Row className="d-flex align-items-center">
                        <Col xs={12} lg={6} className="left-column">
                            <Widget className="widget-auth widget-p-lg">
                                <div className="d-flex align-items-center justify-content-between py-3">

                                    <div className="logo-block">
                                        <SofiaLogo/>
                                        <p className="mb-0">Cesam Khouribga</p>
                                    </div>
                                </div>
                                <div className="auth-info my-2">
                                    <p className="mb-0">Veuillez saisir vos identifiants de connexion</p>
                                </div>
                                <form onSubmit={handleSubmit(submitForm)}>
                                    {error && <ErrorBox message={error}></ErrorBox>}

                                    <FormGroup className="my-3">
                                        <FormText>Email</FormText>
                                        <input
                                            className="form-control input-transparent pl-3 mt-1"

                                            {...register("mail")}
                                            type="email"
                                            required
                                            placeholder="Votre adresse mail"
                                        />
                                    </FormGroup>
                                    <FormGroup className="my-3">
                                        <FormText>Mot de passe</FormText>

                                        <div className={`d-flex align-items-center`}>
                                            <input
                                                id="password"
                                                className="form-control input-transparent pl-3"
                                                type={showPassword ? 'text' : 'password'}
                                                required
                                                {...register("password")}
                                                placeholder="Votre mot de passe"
                                            />
                                            <IconButton onClick={togglePasswordVisibility}
                                                        className={`${s.togglePasswordVisibility} ml-2`}>
                                                {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                            </IconButton>
                                        </div>

                                    </FormGroup>
                                    <div className="bg-widget d-flex justify-content-center">
                                        <Button className="rounded-pill my-3" type="submit"
                                                color="secondary-red">Connexion</Button>
                                    </div>
                                    {loading && <Loader/>}
                                    <p className="dividing-line my-3">&#8195;Ou&#8195;</p>
                                    <a onClick={(e) => {
                                        e.preventDefault()
                                        handleOpenWarning()
                                    }}>
                                        <center>Créer un compte (⚠️Non cesamien)</center>
                                    </a>
                                </form>
                            </Widget>
                        </Col>
                        <Col xs={0} lg={6} className="right-column">
                            <div>
                                <img src={loginImage} alt="Error page"/>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer/>
            </div>
        </>
    )
}


export default withRouter(Login);
