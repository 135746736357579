import React from "react";
import {Switch, Route, Redirect} from "react-router";
import {BrowserRouter} from "react-router-dom";

import UserRoute from "./components/Endpoints/User";
import ErrorPage from "./pages/error/ErrorPage";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import RoleRequiredRoute from "./security/RoleRequiredRoute";
import Role from "./security/Roles";

import {ToastContainer} from "react-toastify";
import "./styles/app.scss";
import LoginRequiredRoute from "./security/LoginRequiredRoute";


const App = () => {
    return (
        <div>
            <ToastContainer/>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact render={() => <Redirect to="/user/dashboard"/>}/>
                    <LoginRequiredRoute path="/user" component={UserRoute}/>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/error" exact component={ErrorPage}/>
                    <RoleRequiredRoute path="/register" exact component={Register} role={Role.ADMIN}/>
                    <Route component={ErrorPage}/>
                    <Route path='*' exact={true} render={() => <Redirect to="/error"/>}/>
                </Switch>
            </BrowserRouter>
        </div>
    );
}


export default App;
