

import locationIcon from "./icon/location.svg";
import clothesIcon from "./icon/clothes.svg";
import foodIcon from "./icon/foods.svg";
import def from "./icon/default.svg";


const servicesIcon = {
    location: locationIcon,
    "vêtements": clothesIcon,
    "alimentation": foodIcon,
    default: def
}
export default servicesIcon;