import folderIcon from "./icons/folder.svg"
import pdfIcon from "./icons/pdf.svg"
import docxIcon from "./icons/docx.svg"
import fileIcon from "./icons/file.svg"
import pptxIcon from "./icons/pptx.svg"
import jpegIcon from "./icons/jpeg.svg"
import mp3Icon from "./icons/mp3.svg"
const icons = {
    "folder": folderIcon,
    "pdf": pdfIcon,
    "docx": docxIcon,
    "pptx": pptxIcon,
    "jpeg": jpegIcon,
    "jpg": jpegIcon,
    "mp3": mp3Icon
}

const defaultIcon = {
    "folder": folderIcon,
    "file": fileIcon
}

export {icons, defaultIcon};