import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import {changeActiveSidebarItem} from "../../slices/navigation";
import CesamLogo from "../Icons/CesamLogo.js";
import cn from "classnames";
import {sideBarOpenedAndActiveItemSelector} from "../../selectors/sideBar";
import PropTypes from "prop-types";
import Role from "../../security/Roles";
import {flagRetriever} from "../../utilities/utilities";
import pharmacy from "./icons/pharmacy.svg";
import market from "./icons/market.svg";
import folder from "./icons/folder.svg";
import home from "./icons/home.svg";
import people from "./icons/people.svg";
import calendar from "./icons/calendar.svg";

const Sidebar = ({user, notificationCount}) => {

    const dispatch = useDispatch();
    const {sidebarOpened, activeItem} = useSelector(sideBarOpenedAndActiveItemSelector)

    const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false)

    useEffect(() => {
        if (sidebarOpened) {
            setBurgerSidebarOpen(true)
        } else {
            setTimeout(() => {
                setBurgerSidebarOpen(false)
            }, 0);
        }
    }, [sidebarOpened])

    return (
        <nav className={cn(s.root, {[s.sidebarOpen]: burgerSidebarOpen})}>
            <header className={s.logo}>
                <CesamLogo/>
                <span className={`${s.title}`}>Cesam Khouribga</span>
            </header>
            <ul className={s.nav}>
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={activeItem}
                    header="Dashboard"
                    isHeader
                    iconName={<img className="mr-3 " alt="Icone associé au services"
                                                src={home}
                                                width="20" height="20"
                                        />}
                    link="/user/dashboard"
                    index="dashboard"
                    badge={notificationCount > 0 ? notificationCount : null}
                />


                <LinksGroup
                    onActiveSidebarItemChange={activeItem => dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={activeItem}
                    header="Archives"
                    isHeader
                    iconName={<img className="mr-3 " alt="Icone associé au services"
                                                src={folder}
                                                width="20" height="20"
                                        />}
                    link="/user/archives"
                    index="Archives"
                />
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={activeItem}
                    header="Services Locaux"
                    isHeader
                    iconName={<img className="mr-3 " alt="Icone associé au services"
                                                src={market}
                                                width="20" height="20"
                                        />}
                    link="/user/local_services"
                    index="ServicesLocaux"
                />
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={activeItem}
                    header="Pharmacie de garde"
                    isHeader
                    iconName={<img className="mr-3 " alt="Icone associé au services"
                                                src={pharmacy}
                                                width="20" height="20"
                                        />}
                    link="/user/pharmacy"
                    index="PharmacieDeGarde"
                />

                {user.Type === Role.ADMIN &&
                    <React.Fragment>
                        <LinksGroup
                            onActiveSidebarItemChange={activeItem => dispatch(changeActiveSidebarItem(activeItem))}
                            activeItem={activeItem}
                            header="Cesamiens"
                            isHeader
                            iconName={<img className="mr-3 " alt="Icone associé au services"
                                                src={people}
                                                width="20" height="20"
                                        />}
                            link="/user/admin/Cesamiens"
                            index="Cesamiens"

                        />
                        <LinksGroup
                            onActiveSidebarItemChange={activeItem => dispatch(changeActiveSidebarItem(activeItem))}
                            activeItem={activeItem}
                            header="Activité"
                            isHeader
                            iconName={<img className="mr-3 " alt="Icone associé au services"
                                                src={calendar}
                                                width="20" height="20"
                                        />}
                            link="/user/admin/Activite"
                            index="Activite"

                        />
                    </React.Fragment>
                }
            </ul>

        </nav>
    );
}

Sidebar.propTypes = {
    /*required*/
    user: PropTypes.object.isRequired,
}

export default withRouter(Sidebar);
