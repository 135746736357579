import React, {useState} from "react";
import ApexCharts from "react-apexcharts";
import {Button} from "reactstrap";
import {SucessBox} from "../../../components/Alert/Sucess";
import {ErrorBox} from "../../../components/Alert/Alert";

const maxCotisation = 20;
const numMonths = 12;

function chartSettings(labels) {
    return {
        colors: ["#F7F8FB", "#FF5668"],
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: false,
            },
        },
        stroke: {
            curve: "straight",
            width: [0, 1]
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
            style: {
                fontSize: '10px',
                fontWeight: 500,
            },
            background: {
                borderWidth: 0,
            },
        },

        labels: ["jan", "fév", "mar", "avr", "mai", "jui", "juil", "aoû", "sep", "oct", "nov", "déc"],
        xaxis: {
            type: 'category',
            labels: {
                style: {
                    colors: "#6B859E",
                },
            },
        },
        yaxis: [
            {

                labels: {
                    style: {
                        colors: ["#6B859E"],
                    },
                },
            }, {
                opposite: true,

                labels: {
                    style: {
                        colors: ["#6B859E"],
                    },
                },
            }],
        fill: {
            type: "solid",
            opacity: 1,
        }
    };
}

export default function ApexLineColumnChart({contribution, is_up_to_date}) {
    const cotisations = Object.fromEntries(
        Object.entries(contribution).map(([year, months]) => [
            year,
            Object.fromEntries(Object.entries(months)
                .sort((a, b) => a[0] - b[0])),
        ])
    );

    const maxYear = new Date().getFullYear();
    const minYear = Math.min(...Object.keys(cotisations));
    const [currentYear, setCurrentYear] = useState(maxYear);
    const series = [{
        name: 'Somme à payer ',
        type: 'column',
        data: Array.from({length: numMonths}, () => maxCotisation)
    }, {
        name: 'Montant payé ',
        type: 'line',
        data: Object.values(cotisations[currentYear])
    }];
    const nextYear = () => {
        if (currentYear < maxYear) {
            setCurrentYear(currentYear + 1);
        }
    };

    const prevYear = () => {
        if (currentYear > minYear) {
            setCurrentYear(currentYear - 1);
        }
    };
    return (
        <div className={"p-xl-2"}>
            <h6 className={"text-center"}>{currentYear}</h6>
            {is_up_to_date !== undefined &&
                <div className={"text-center m-5"}>
                    {is_up_to_date === 0 ?
                        <SucessBox message={"🍾 Bravo vous êtes à jour dans vos cotisations"}/> :

                        <ErrorBox message={`⚠️ Cotisation en retard
                                                de ${is_up_to_date} mois`}/>}
                </div>}

            <ApexCharts
                options={chartSettings(Object.keys(cotisations[currentYear]))}
                series={series}
                type="area"
                height={300}
            />
            <div className={"d-flex align-items-center justify-content-between flex-column flex-lg-row"}>
                {currentYear !== minYear &&
                    <Button color={'primary'} className={"m-3"} onClick={prevYear}>Année précédente</Button>}
                {currentYear !== maxYear &&
                    <Button color={'primary'} className={"m-3 ml-lg-auto"} onClick={nextYear}>Année suivante</Button>}
            </div>

        </div>


    )
};

