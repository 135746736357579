import { Form, FormGroup, Input, InputGroup } from "reactstrap";

export default function SearchBar({ placeholder, onFocus, onChange, className, onBlur }) {
    return (<Form className={`d-flex align-items-center justify-content-center ${className}`} inline>
        <FormGroup>
            <InputGroup className='input-group-no-border mt-3'>
                <Input id="search-input"
                    placeholder={placeholder}
                    className='focus w-100'
                    onFocus={onFocus}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                />

            </InputGroup>
        </FormGroup>
    </Form>)
}