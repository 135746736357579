import React from 'react';
import logo from './logo.svg';
import {Link} from "react-router-dom";

class CesamLogo extends React.Component {

    render() {
        return (
            <Link to="/user" relative="path">
                <img src={logo} alt={"logo"} width={60}/>
            </Link>


        );
    }
}

export default CesamLogo;
