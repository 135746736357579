// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {BASE_URL} from "../config";



export const localServicesApi = createApi({
    reducerPath: 'localServicesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/Services`

    }),
    endpoints: (builder) => ({
        getServices: builder.query({
            query: () => ({
                url: ``,
                method: 'GET',
            }),
        }),
        getPharmacy: builder.query({
            query: (coords: Coordinates | null) => ({
                url: `/pharmacy`,
                method: 'GET',
                params: {
                    coords: coords ? `${coords.longitude},${coords.latitude}`: ''
                }
            }),
        }),

    })
})

export const {
    useGetServicesQuery,
    useGetPharmacyQuery
} = localServicesApi