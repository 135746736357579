import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {BASE_URL} from "../config";


export const bourseApi = createApi({
    reducerPath: 'bourseApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/bourse`,
        prepareHeaders: (headers, {getState}) => {
            // @ts-ignore
            const token = getState().authentication.userToken
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        },

    }),
    endpoints: (builder) => ({
        getAmciCode: builder.query({
            query: (matricule: string) => ({
                url: ``,
                params: {
                    code: matricule
                },
                method: 'GET',
            }),
        }),
    })
})

export const {
    useGetAmciCodeQuery,
} = bourseApi;