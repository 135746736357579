import {createSlice} from "@reduxjs/toolkit";
import {registerUser} from "../thunks/register";

const userToken: string | null = localStorage.getItem('userToken') || null


const register = createSlice(
    {
        name: 'register',
        initialState: {
            loading: false,
            userToken,
            error: null,
            success: false,
        },
        reducers: {},
        extraReducers: {
            // register user
// @ts-ignore

            [registerUser.pending]: (state: AuthState) => {
                state.loading = true
                state.error = null
            },
// @ts-ignore

            [registerUser.fulfilled]: (state: AuthState) => {
                state.loading = false
                state.success = true // registration successful
            },
// @ts-ignore

            [registerUser.rejected]: (state: AuthState, {payload}) => {
                state.loading = false
                state.error = payload
            },
        }

    }
)



export default register.reducer;
