import React from "react";

export function ErrorBox(props:{message: string, className?: string}) {
    const {message} = props;
    const className = props.className ? props.className : "";
    return <div className={`alert alert-danger text-center text-black-50 ${className}`} role={"alert"}>
        {message}
    </div>
}

