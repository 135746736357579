import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {
    Col, DropdownItem, DropdownMenu, DropdownToggle,
    Row,
    Table, UncontrolledDropdown,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import {icons, defaultIcon} from "./icons";
import back from "../../assets/tables/back.svg";
import s from "./Tables.module.scss";
import {Loader} from "../../components/Loader/Loader";
import {ErrorBox} from "../../components/Alert/Alert";
import schools from "./data/schools";
import useLocalStorage from "../../hooks/useLocalStorage";
import {IconButton} from "@material-ui/core";
import {useTravelTreeQuery} from "../../services/archive";

function evalPathLevel(path) {
    return path.split("/").length
}


function searchElementIcon(element) {
    if (element["is_file"]) {
        /* Pop the last element of the array */
        const extension = element.base_name.split(".").pop()
        if (extension in icons) {
            return icons[extension]

        } else {
            return defaultIcon['file']

        }
    }
    return defaultIcon['folder']
}

function findParents(path) {
    let parents = path.split("/").slice(0, -2).slice(1)

    if (parents.length > 0) {
        return parents.map((parent, index) => {
            return {
                isBase: index === 0,
                path: "Archives/" + parents.slice(0, index + 1).join("/") + "/",
                base_name: parent + "/",
            }
        })
    }
    return []

}

const Archives = function () {
    const initialPath = "Archives/"
    let initialSection = "Ensa"
    let initialFolder = `${initialPath}${initialSection}/`
    const [expanded, setExpanded] = useState(false)

    const [path, setPath] = useLocalStorage(
        "path",
        {
            path: initialFolder,
            base_name: `${initialSection}/`
        }
    )
    const {data, isFetching, error} = useTravelTreeQuery(path.path)
    const [section, setSection] = useLocalStorage(
        "section",
        initialSection
    )
    const [isClicked, setIsClicked] = useState(false)
    useEffect(() => {
        if (isClicked) {
            const newPath = `${initialPath}${section}/`;
            const newBaseName = `${section}/`;
            if (path.path !== newPath) {

                setPath(
                    {
                        path: newPath,
                        base_name: newBaseName
                    }
                )
            }
            setIsClicked(false)
        }

    }, [section])
    const content = function () {
        if (isFetching) {
            return <Loader className={"m-3"} center={true}/>
        } else if (error) {
            // @ts-ignore
            return <ErrorBox message={error.data.message} className={"mx-3"}/>
        } else {
            return <React.Fragment>
                {[...data.children].sort((a, b) => {
                    a = a.base_name.toLowerCase();
                    b = b.base_name.toLowerCase();
                    return a < b ? -1 : a > b ? 1 : 0;
                })?.map(item => (

                    <tr style={{cursor: "pointer"}} key={uuidv4()}
                        onClick={() => {
                            if (!item["is_file"]) {
                                setIsClicked(true)
                                setPath(prevPath => {
                                        return {
                                            path: prevPath.path + item.base_name,
                                            base_name: item.base_name,
                                        }
                                    }
                                )
                            } else {
                                /*  open for download */
                                window.open(item["download_link"], "_blank")
                            }
                        }}>

                        <td className="d-flex align-items-center"
                        ><span
                            className="ml-3 d-flex align-items-center">
                                                            <img className={"mr-2"} src={searchElementIcon(item)}
                                                                 width={30} height={30}
                                                                 alt="Folder"/>
                            {!item["is_file"] ? item.base_name.replace('/', "") : item.base_name}
                        </span>

                        </td>
                    </tr>
                ))}</React.Fragment>

        }
    }
    const dropdown = function () {
        const parents = findParents(path.path)
        if (parents.length > 0)
            return parents
                .reverse()
                .map(el =>
                    <DropdownItem onClick={() => {
                        setIsClicked(true)
                        setPath(
                            {
                                path: el.path,
                                base_name: el.base_name,
                            }
                        )
                    }}
                    >
                        <div>
                            <IconButton style={{
                                width: "1px",
                                height: "1px",
                            }}>
                                {el.isBase ?
                                    <i className={`fa fa-home text-primary`}/> :
                                    <i className={`fa fa-long-arrow-down text-primary`}/>
                                }
                            </IconButton>

                            <span
                            > {el.base_name}</span>
                        </div>
                    </DropdownItem>
                )
        else
            return <DropdownItem>
                <div>
                    <IconButton style={{
                        width: "1px",
                        height: "1px",
                    }}>
                        <i className={`fa fa-home text-primary`}/>
                    </IconButton>

                    <span
                    > {path.base_name}</span>
                </div>
            </DropdownItem>

    }

    return (
        <div>

            <Row>
                <Col>
                    <Row className="mb-4">
                        <Col>


                            <Widget>
                                <div className={`${s.tableTitle} d-flex flex-column`}>
                                    <UncontrolledDropdown
                                        className={"d-flex justify-content-end mb-2 align-items-center "}>
                                        <p className={"font-weight-bold mr-2"}>Choisissez votre école</p>
                                        <DropdownToggle caret>
                                            {section}
                                        </DropdownToggle>
                                        <DropdownMenu caret>
                                            {Object.keys(schools).filter((key) => key !== section)
                                                .map((key) =>
                                                    <DropdownItem
                                                        onClick={() => {
                                                            setSection(key)
                                                            setIsClicked(true)
                                                        }

                                                        }>{key}</DropdownItem>
                                                )}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <div className={"d-flex align-items-center mb-2"}>
                                        {evalPathLevel(path.path) > 3 &&
                                            <div className={`d-flex mr-3 `} onClick={() => {
                                                let newPath = path.path.split("/").slice(0, -2)

                                                setPath(
                                                    {
                                                        path: newPath.join("/") + "/",
                                                        base_name: newPath[newPath.length - 1] + "/",
                                                    }
                                                )
                                            }}>

                                                <button
                                                    className={`${s.btn} ${s.hoverHand} ${expanded ? `d-lg-block d-none` : ""}`}>
                                                    <img src={back} alt="Back"/></button>
                                            </div>
                                        }
                                        <UncontrolledDropdown className={"d-flex justify-content-end "}>
                                            <DropdownToggle caret className={"bg-primary text-white text-wrap"}>
                                                {path.base_name.replace('/', "")}
                                            </DropdownToggle>
                                            <DropdownMenu caret>


                                                {dropdown()}


                                            </DropdownMenu>
                                        </UncontrolledDropdown>


                                    </div>


                                </div>

                                <div className="widget-table-overflow">
                                    <Table className={`table-striped table-borderless table-hover `}
                                           responsive>
                                        <tbody>
                                        {content()}
                                        </tbody>
                                    </Table>
                                </div>


                            </Widget>


                        </Col>
                    </Row>

                </Col>
            </Row>
        </div>
    )
}

export default Archives;
