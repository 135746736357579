import Widget from "../Widget/Widget";
import {Loader} from "../Loader/Loader";
import React from "react";
import sDashboard from "../../pages/dashboard/Dashboard.module.scss";
import key from "./icons/key.svg";
import identifier from "./icons/identifier.svg";
import user from "./icons/user.svg";
import update_date from "./icons/update_date.svg";
import {ErrorBox} from "../Alert/Alert";
import {flagRetriever} from "../../utilities/utilities";
import Planning from "./planning";
import moment from "moment";
import planningIcon from "./icons/planning.svg";
import {useGetAmciCodeQuery} from "../../services/bourse";

function getUpdatedDate(date: string): string {
    return moment(date).format('dddd DD MMMM YYYY à HH:mm')
}

function Amci(props: { matricule: string }) {
    const {matricule} = props;
    const {data, isFetching, error} = useGetAmciCodeQuery(matricule)

    const content = function () {
        if (isFetching) return <div className={`my-4 d-flex align-items-center justify-content-center `}>
            <Loader/>
        </div>

        else if (error) {
            // @ts-ignore
            return <ErrorBox message={error.data.message}/>
        }
        const svgImage = {
            code: key,
            matricule: identifier,
            nom_prenoms: user,
            pays: flagRetriever(data.pays),
            planning: planningIcon,
            "Date de modification": update_date
        }
        const updatedData = {
            ...data,
            "Date de modification": getUpdatedDate(data["Date de modification"])
        }
        return (<React.Fragment>
            <Planning plannings={updatedData["planning"]} svgImage={svgImage}/>
            {Object.keys(updatedData)
                .filter((value) => value !== "planning")
                .map((value) =>
                    <div className={`mt-4 ${sDashboard.widgetBlock}`} key={value}>
                        <div className={sDashboard.widgetBody}>
                            <div className="d-flex">
                                <img className="img-fluid mr-3 "
                                     alt="..."
                                     src={svgImage[value]}
                                     width={40}
                                     height={40}
                                />
                                <div className="d-flex flex-column">
                                    <p className="body-2 mb-2">{value.toUpperCase()}</p>
                                    <p className="body-3 muted">{updatedData[value]}</p>
                                </div>
                            </div>

                        </div>
                    </div>)}
        </React.Fragment>)

    }

    return (
        <div>
            <Widget className="widget-p-md ">


                {content()}
            </Widget>

        </div>
    );
}

export default Amci;


