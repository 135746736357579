import React from "react";

import ApexLineColumnChart from "../activity/components/ApexLineColumnChart";


import {
    Col,
    Row,
    Button,

} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import CommunityChart from "../../components/CommunityChart/CommunityChart";

import upgradeImage from "../../assets/dashboard/upgradeImage.svg";

import s from "./Dashboard.module.scss";
import Amci from "../../components/Amci/Amci.tsx";
import Evenement from "../../components/Evenements/Evenements"
import {useDispatch} from "react-redux";
import {blurBackground, unBlurBackground} from "../../slices/navigation";
import ROLES from "../../security/Roles";
import UsefulLink from "../../components/UsefulLink/UsefulLink";
import ScrollableModal from "../../components/ScrollableModal/ScrollableModal";
import {ErrorBox} from "../../components/Alert/Alert";


const Dashboard = ({user}) => {

    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const buttonLink = {
        linkedin: () => {
            window.location.href = "https://www.linkedin.com/company/cesam-khouribga/"
        },
        instagram: () => {
            window.location.href = "https://www.instagram.com/cesamkhouribga/"
        },
        whatsapp: () => {
            window.location.href = "https://wa.me/00212638864661"
        }
    }
    const handleClose = () => {
        setOpen(false);
        dispatch(unBlurBackground())
    };


    const handleOpen = () => {
        setOpen(true);
        dispatch(blurBackground())
    };


    return (
        <div>
            <Row className={"d-flex align-items-start"}>
                <Col className="mt-4 mt-lg-0 pl-grid-col order-lg-2 mb-4" xs={12} lg={5}>
                    <Widget className="widget-p-lg">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={s.container}>
                                <img className={`${s.image} ${s.userpic}`} src={user.picture_link} alt="..."/>
                            </div>
                            <div className={s.userInfo}>
                                <p className="headline-3 text-right "> {user.name} {user.surname}</p>
                                <p className="body-3 muted text-right">{user["nationality"]}</p>
                            </div>
                        </div>

                        {user.Type !== ROLES.USER &&
                            <button type="button" className={` ${s.statsBtnBourse}`}
                                    onClick={handleOpen}>BOURSE AMCI
                            </button>}
                        {user.Type !== ROLES.USER &&
                            <ScrollableModal
                                onClose={handleClose}
                                open={open}
                            >
                                {user["amciIdentifier"] ?
                                    <Amci matricule={user["amciIdentifier"]}/> : <ErrorBox
                                        message={"Nous ne disposons pas de votre code Amci, veuillez le renseigner dans la section d'édition du profile"}/>}
                            </ScrollableModal>}


                        <div className={s.goals}>


                            <h3 className={"text-center mb-3"}>Activités à venir</h3>


                            <Evenement user={user}/>


                        </div>


                    </Widget>
                </Col>

                <Col className="pr-grid-col order-lg-1" xs={12} lg={7}>
                    {user.Type !== ROLES.USER &&
                        <Row className="gutter mb-4">
                            <Col className="mb-4 mb-md-0" xs={12} md={12}>
                                <Widget className="">
                                    <div className="d-flex justify-content-between widget-p-md">
                                        <div className="headline-3 text-center w-100">Mes Cotisations</div>
                                    </div>

                                    <ApexLineColumnChart contribution={user.contribution}
                                                         is_up_to_date={user.is_up_to_date}/>
                                </Widget>
                            </Col>
                        </Row>}


                    <Row className="gutter mb-4">
                        <Col className="mb-4 " xs={12}>
                            <Widget
                                className="text-center d-flex align-items-center flex-column justify-content-center">


                                <CommunityChart className="pb-2 "/>
                            </Widget>
                        </Col>
                        <Col xs={12}>
                            <UsefulLink/>
                        </Col>
                    </Row>


                    <Row className="gutter mb-4">
                        <Col xs={12}>
                            <Widget className="widget-p-none">
                                <div className="d-flex flex-wrap align-items-center justify-content-center">
                                    <div className="d-flex flex-column align-items-center  col-12 col-xl-8 p-4">
                                        <p className="headline-1">Contactez nous</p>

                                        <div className="d-flex row justify-content-between my-4">
                                            <Button className="rounded-pill col-xs-12 col-xl m-3"
                                                    color="linkedin" onClick={buttonLink.linkedin}
                                            >Linkedin</Button>
                                            <Button className="rounded-pill col-xs-12 col-xl m-3"
                                                    color="danger" onClick={buttonLink.instagram}
                                            >Instagram</Button>
                                            <Button className="rounded-pill col-xs-12 col-xl m-3"
                                                    color="success" onClick={buttonLink.whatsapp}
                                            >WhatsApp</Button>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center col-12 col-xl-4">
                                        <img className="p-1 img-fluid" src={upgradeImage} alt="..."/>
                                    </div>
                                </div>
                            </Widget>
                        </Col>

                    </Row>


                </Col>
            </Row>
        </div>
    )
}


export default Dashboard;
