const countries = {
    "burkina faso": "BF",
    "bénin": "BJ",
    "benin": "BJ",
    "burkina-faso": "BF",
    "comorres": "KM",
    "comoros": "KM",
    "côte d'ivoire": "CI",
    "cameroun": "CM",
    "cameroon": "CM",
    "djibouti": "DJ",
    "gabon": "GA",
    "ghana": "GH",
    "guinée": "GN",
    "guinée conakry": "GN",
    "guinea": "GN",
    "guinée bissau": "GW",
    "guinea bissau": "GW",
    'france': 'FR',
    "liberia": "LR",
    "libéria": "LR",
    "madagascar": "MG",
    "maroc": "MA",
    "mali": "ML",
    "niger": "NE",
    "nigeria": "NG",
    "nigéria": "NG",
    "sénégal": "SN",
    "senegal": "SN",
    "tchad": "TD",
    "chad": "TD",
    "togo": "TG",


}

export default countries;
