// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {BASE_URL} from "../config";


export const functionalityApi = createApi({
    reducerPath: 'functionalityApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/Users/functionality`,
        prepareHeaders: (headers, {getState}) => {
            // @ts-ignore
            const token = getState().authentication.userToken
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        },

    }),
    endpoints: (builder) => ({

        getNotification: builder.query({
            query: (user_id) => ({
                url: `/${user_id}/notifications`,
                method: 'GET',
            }),

        }),
        getNationalityCount: builder.query({
            query: () => ({
                url: `/nationality`,
                method: 'GET',
            }),
        }),
        getContributionSum: builder.query({
            query: () => ({
                url: `/contribution`,
                method: 'GET',
            }),
        })
    })
})

export const {
    useGetNationalityCountQuery,
    useGetContributionSumQuery,
    useGetNotificationQuery
} = functionalityApi