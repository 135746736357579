import React from "react";
import {
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import s from "./UnauthorizedPage.module.scss";

import errorImage from "../../assets/errorImage.svg"
import FooterIcon from "../../components/Icons/FooterIcon.js";

const UnauthorizedPage = () => {
  return (
    <div className={s.pageContainer}>
      <div className={s.errorContainer}>
        <h1 className={s.errorCode}>401</h1>

        <p className={s.errorInfo}>
          Oops. Vous n'avez pas l'autorisation d'accéder à cette page.
        </p>
        <p className={s.errorHelp}>
          Mais ne vous inquiétez pas, vous pouvez revenir à la page d'accueil.
        </p>
        <Link to="/user/dashboard">
          <Button className={`${s.errorBtn} rounded-pill`} type="submit" color="secondary-red">
            Retour à la page d'accueil
          </Button>
        </Link>
      </div>
      <div className={s.imageContainer}>
        <img className={s.errorImage} src={errorImage} alt="Error page" width="80" />
      </div>
      <div className={s.footer}>
        <span className={s.footerLabel}>2023 &copy; Cesam Khouribga</span>
        <FooterIcon />
      </div>
    </div>
  );
}

export default UnauthorizedPage;
