import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import links from "../../pages/dashboard/links";
import s from "../../pages/dashboard/Dashboard.module.scss";
import Widget from "../Widget/Widget";
import {useState} from "react";

function UsefulLink() {
    const [section, setSection] = useState("Transport");
    return (
        <Widget className="widget-p-md">
            <div className="d-flex justify-content-between">
                <div className="headline-3 d-flex align-items-center">Liens Utiles</div>
                <UncontrolledDropdown>
                    <DropdownToggle caret={true}>
                        {section}
                    </DropdownToggle>
                    <DropdownMenu caret={true}>
                        {Object.keys(links).filter((key) => key !== section).map((key) =>
                            <DropdownItem onClick={() => setSection(key)} key={key}
                            >{key}</DropdownItem>
                        )}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
            {links[section].map((link) =>
                <div className={`mt-4 ${s.widgetBlock}`} key={link.name}>
                    <div className={s.widgetBody}>
                        <div className="d-flex align-items-center  ">
                            <img className=" mr-3 rounded" alt="..." src={link.icon}
                                 width="50" height="50"
                            />
                            <div className="d-flex flex-column">
                                <p className="body-2">{link.name}</p>
                                <p className="body-3 muted">{link.description}</p>
                                <p className="body-3 muted"><a href={link.url} target="_blank"
                                                               rel="noopener noreferrer">
                                    Accéder </a></p>
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </Widget>
    )
}

export default UsefulLink;