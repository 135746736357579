import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route} from "react-router";
import React from "react";
import {useGetUserDetailsQuery} from "../services/authService";
import {Loader} from "../components/Loader/Loader";
import {logout} from "../slices/auth";
import LogRocket from 'logrocket';
import UnauthorizedPage from "../pages/Unauthorized/Unauthorized";

const RoleRequiredRoute = ({component, role, ...rest}) => {

    const {data, isFetching, error} = useGetUserDetailsQuery('userDetails', {
        // perform a refetch every 15mins
        pollingInterval: 900000,
        /* retry settings */

    })
    const success = useSelector(state => state.authentication.success)
    const dispatch = useDispatch()
    if (isFetching) {
        return <Loader center={true}/>
    } else if (error) {


        localStorage.getItem('userToken') && !success && localStorage.removeItem('userToken') && dispatch(logout())

        return <Redirect to="/login"/>
    } else {
        const user = data.data
        if (user.Type !== role) {
            return <Route {...rest} component={UnauthorizedPage}/>
        }
        LogRocket.identify('cmv0ib/cesam-website', {
            name: user.name,
            email: user.surname,

        });
        const createComponent = (props) => {
            return React.createElement(component, {
                ...props,
                user
            })
        }
        return (
            <Route {...rest} render={createComponent}
            />
        );
    }

};

export default RoleRequiredRoute;
