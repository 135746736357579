import Widget from "../../../components/Widget/Widget";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Button, Col, FormGroup, Row} from "reactstrap";
import s from "../../../pages/dashboard/Dashboard.module.scss";
import sCustom from "../../../components/Header/components/style.module.scss";
import {useUpdateUserMutation} from "../../../services/user";
import {Loader} from "../../../components/Loader/Loader";
import {ErrorBox} from "../../../components/Alert/Alert";
import {SucessBox} from "../../../components/Alert/Sucess";

const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
]

export default function ContributionCesamien({contribution, cesamien}: {
    contribution: {
        [key: number]: {
            [key: number]: number
        }
    }
    cesamien: User
}) {
    // @ts-ignore
    const maxYear = Math.max(...Object.keys(contribution))
    // @ts-ignore
    const minYear = Math.min(...Object.keys(contribution))
    const [currentYear, setCurrentYear] = useState(maxYear)
    const nextYear = () => {
        if (currentYear < maxYear) {
            setCurrentYear(currentYear + 1);
        }
    };

    const prevYear = () => {
        if (currentYear > minYear) {
            setCurrentYear(currentYear - 1);
        }
    };
    const {register, handleSubmit} = useForm();
    const [updateUser, {isLoading}] = useUpdateUserMutation()
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [sucessMessage, setSucessMessage] = useState<string | null>(null)


    const submitForm = (data) => {

        const updatedContribution = {...contribution}
        /* Convert data to int */
        Object.keys(data).forEach((key) => {
            data[key] = parseInt(data[key])
        })
        updatedContribution[currentYear] = data
        if (updatedContribution[currentYear] === contribution[currentYear]) {
            setErrorMessage("Aucune modification n'a été effectuée")
            return
        }
        const formData = new FormData()
        formData.append("contribution", JSON.stringify(updatedContribution))


        if (formData.entries().next().done) {
            setErrorMessage("Aucune modification n'a été effectuée")
        } else {

            try {
                const userData = {
                    user_id: cesamien.id,
                    data: formData
                }
                updateUser(userData)
                    .unwrap()
                    .then((res) => {
                        setSucessMessage("Les modifications ont été effectuées avec succès, veuillez " +
                            "actualiser la page pour voir les modifications")
                        setErrorMessage(null)
                    })
                    .catch((e) => {
                        setErrorMessage(e)
                    })
            } catch (e) {

            }
        }
    }

    return (
        <div>
            <Widget className="widget-p-md ">
                <form onSubmit={handleSubmit(submitForm)} className={"d-flex flex-column justify-content-center"}>
                    <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                        <div className={"mr-2 "}>
                            <i className={`fa p-2 rounded fa-chevron-left ${currentYear !== minYear && "btn-primary"
                            }`} onClick={prevYear}>

                            </i>
                        </div>

                        <h5 className={"text-center font-weight-bold"}>{currentYear}</h5>
                        <div className={"ml-2"}>
                            <i className={`fa p-2 rounded fa-chevron-right ${currentYear !== maxYear && "btn-primary"
                            }`} onClick={nextYear}>

                            </i>
                        </div>

                    </div>
                    <FormGroup>
                        <Row>
                            {
                                Object.keys(contribution[currentYear])
                                    // @ts-ignore

                                    .map((month, index) => {
                                        return (
                                            <Col xs={6} lg={4} className={"mb-2"}>
                                                <p className={"body-2"}>{
                                                     // @ts-ignore
                                                    months[month - 1]}</p>
                                                <input

                                                    className="form-control input-transparent mt-2 pl-3"
                                                    type="number"
                                                    min={0}
                                                    max={20}
                                                    {...register(month)}
                                                    defaultValue={contribution[currentYear][month]}
                                                />
                                            </Col>
                                        )
                                    })
                            }

                        </Row>


                    </FormGroup>
                    <FormGroup className=" mr-3">
                        <Row>
                            <Col className={"d-flex align-items-center flex-column  justify-content-center"}>
                                <Button className="rounded-pill my-3  " type="submit"
                                        color="secondary-red">Enregistrer Contribution</Button>
                                {errorMessage !== null && <div className={"mt-3"}>
                                    <ErrorBox message={`${errorMessage}`}/></div>}

                                {sucessMessage !== null && <div className={"mt-3"}>
                                    <SucessBox message={`${sucessMessage}`}/></div>}
                            </Col>
                            {isLoading && <Loader/>}

                        </Row>

                    </FormGroup>
                </form>


            </Widget>

        </div>
    )
}