import React, {useState} from "react";
import {useForm} from "react-hook-form";

import {useUpdateActivitiesMutation} from "../../../../services/activity";
import moment from "moment";

import EventForm from "../eventForm";


export default function Edit({activity, setActivityEdited}) {
    const {register, handleSubmit} = useForm();
    const [updateActivities, {isLoading}] = useUpdateActivitiesMutation()
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const submitForm = (data) => {


        const formData = {
            date: moment(data["date"] + "T" + data["time"]).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        }
        if (data["name"] !== activity.name) {
            formData["name"] = data["name"]
            // @ts-ignore
            formData.append("nom", data["nom"])
        }

        if (data["description"] !== activity.description) {
            formData["description"] = data["description"]
        }

        if (data["actif"] !== activity.actif) {
            formData["actif"] = data["actif"]
        }


        if (data["price"] !== activity.price) {
            formData["price"] = data["price"]
        }
        // @ts-ignore

        if (formData.length === 0) {
            // @ts-ignore
            setErrorMessage("Aucune modification n'a été effectuée")
        } else {

            try {
                const userData = {
                    activity_id: activity.id,
                    data: formData
                }
                updateActivities(userData)
                    .unwrap()
                    .then(() => {
                        // @ts-ignore
                        setSuccessMessage("Les modifications ont été effectuées avec succès")
                        setErrorMessage(null)
                        setActivityEdited(true)
                    })
                    .catch((e) => {
                        console.log(e)

                        setErrorMessage(e.message)
                    })
            } catch (e) {
                console.log(e)
                // @ts-ignore
                setErrorMessage(e.message)
                setSuccessMessage(null)
            }
        }
    }


    return (
        <EventForm
            title={"Modifier une activité"}
            handleSubmit={handleSubmit}
            activity={activity}
            mode={"edit"}
            submitForm={submitForm}
            errorMessage={errorMessage}
            successMessage={successMessage}
            register={register}
            isLoading={isLoading}
            instruction={"Modifier l'activité"}
        />
    )
}