import React, {useMemo, useState} from "react";
import {withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavItem, NavLink,} from "reactstrap";

import {blurBackground, closeSidebar, openSidebar, unBlurBackground} from "../../slices/navigation";
import MenuIcon from "../Icons/HeaderIcons/MenuIcon";


import ProfileIcon from "../../assets/navbarMenus/pfofileIcons/ProfileIcon";

import logoutIcon from "../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg";


import s from "./Header.module.scss";
import "animate.css";
import {sideBarOpenedSelector} from "../../selectors/sideBar";
import {userLogout} from "../../thunks/logout";
import {Loader} from "../Loader/Loader";
import UpdateProfile from "./components/update_profile";
import infoIcon from "./icons/info.svg";
import warningIcon from "./icons/warning.svg";
import errorIcon from "./icons/error.svg";
import planningIcon from "./icons/planning.svg";
import Empty from "../Empty/Empty";
import Notification from "../Notification/Notification";
import ScrollableModal from "../ScrollableModal/ScrollableModal";
import {useUpdateUserMutation} from "../../services/user";
import {ErrorBox} from "../Alert/Alert";
import {error} from "logrocket";
import moment from "moment";
import 'moment/locale/fr'
import {flagRetriever} from "../../utilities/utilities";

const NotificationIcon = {
    info: infoIcon,
    warning: warningIcon,
    error: errorIcon,
    planning: planningIcon,
}

function getDate(date) {
    /* Je veux le format jeudi 12 novembre 2020 en français */
    const dateObj = moment(date).format("dddd DD MMMM YYYY")

    return dateObj.charAt(0).toUpperCase() + dateObj.slice(1)
}

function getHour(date) {
    return moment(date).format("HH:MM")
}

const Header = ({user, location, isFetchingNotification, notifications, refetchNotification, errorNotification}) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const dispatch = useDispatch();
    const sidebarOpened = useSelector(sideBarOpenedSelector);
    const token = useSelector(state => state.authentication.userToken)
    const isLoading = useSelector(state => state.authentication.loading)
    const [updateUser] = useUpdateUserMutation()
    const [selectedNotification, setSelectedNotification] = useState({
        icon: "",
        notification: {}
    })
    const countryFlag = useMemo(() => flagRetriever(user["nationality"]), [user["nationality"]])
    const toggleNotifications = () => {
        setNotificationsOpen(!notificationsOpen);
    }

    const handleClose = () => {
        setOpen(false);
        dispatch(unBlurBackground())
    };


    const handleOpen = () => {

        setOpen(true);
        dispatch(blurBackground())
    };
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const toggleSidebar = () => {
        if (sidebarOpened) {
            dispatch(closeSidebar());
        } else {
            const paths = location.pathname.split('/');
            paths.pop();
            dispatch(openSidebar());
        }
    }
    const [openNotification, setOpenNotification] = React.useState(false);
    const handleOpenNotification = (notification) => {
        setSelectedNotification(
            {
                icon: NotificationIcon[notification.type],
                notification: notification
            }
        )
        if (!notification.is_read) {
            const readedNotification = {...notification, is_read: true}
            const updatedNotification = notifications.data.map((key) => {
                if (key.title === notification.title && key.date === notification.date) {
                    return readedNotification
                }
                return key
            })
            const formData = new FormData();
            formData.append("notification", JSON.stringify(updatedNotification))
            const userData = {
                user_id: user.id,
                data: formData
            }
            updateUser(userData)
                .unwrap()
                .then((res) => {
                    console.log(res)
                    refetchNotification()
                })
                .catch((e) => {
                    console.log(e)
                })

        }

        setOpenNotification(true);
        dispatch(blurBackground())
    };

    const handleCloseNotification = () => {
        setSelectedNotification(
            {
                icon: "",
                notification: {}
            }
        )

        setOpenNotification(false);
        dispatch(unBlurBackground())
    };

    /* Check if user has notifications */
    const content = user.notification.length > 0 ? (
        isFetchingNotification ? <Loader/> :
            errorNotification ? <ErrorBox message={error}/> :
                [...notifications.data]
                    .sort((a, b) => {
                        return new Date(b.date) - new Date(a.date)
                    })
                    .map((key, value) => {

                        return (
                            <div className={`${!key.is_read ? s.notReaded : undefined} my-2`} key={value}>
                                <DropdownItem onClick={() => handleOpenNotification(key)}

                                >

                                    <div>
                                        <div className="d-flex flex-row mb-1">
                                            <img src={NotificationIcon[key.type]} alt="" className={s.mariaImage}
                                            />
                                            <div className="d-flex flex-column">

                                                <p className="body-2 muted">
                                                    {key.title}

                                                </p>
                                                <p className="label muted">
                                                    {Date.parse(key.date) >= Date.now() ? "Aujourd'hui" : getDate(key.date)} à {getHour(key.date)}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </DropdownItem>

                            </div>
                        )
                    })) : <Empty text="Pas de notifications" style={"text-black"}/>

    return (
        <Navbar className={`${s.root} d-print-none`}>
            <ScrollableModal
                onClose={handleCloseNotification}
                open={openNotification}

            >
                <Notification notification={selectedNotification.notification}
                              icon={selectedNotification.icon}
                />
            </ScrollableModal>
            <div>
                <NavLink
                    onClick={() => toggleSidebar()}
                    className={`d-md-none mr-3 ${s.navItem}`}
                    href="#"
                >
                    <MenuIcon className={s.menuIcon}/>
                </NavLink>
            </div>


            <Nav className="ml-auto">

                <Dropdown nav isOpen={menuOpen} toggle={() => toggleMenu()} className="tutorial-dropdown mr-2 mr-sm-3">
                    <DropdownToggle nav>
                        <div className={s.navbarBlock}>
                            <i className={`eva eva-bell-outline ${!isFetchingNotification && !errorNotification && [...notifications.data]
                                .filter((key) => {
                                        return key.is_read === false
                                    }
                                ).length > 0
                            && s.swingIcon}`}/>


                            {!isFetchingNotification && !errorNotification && [...notifications.data]
                                    .filter((key) => {
                                            return key.is_read === false
                                        }
                                    ).length > 0
                                && <div className={`${s.count} ${s.shakeIcon}`}></div>}

                        </div>
                    </DropdownToggle>

                    <DropdownMenu right className="navbar-dropdown notifications-dropdown" style={{width: "auto "}}>
                        <h3>Notifications</h3>
                        {content}


                    </DropdownMenu>

                </Dropdown>

                <Dropdown isOpen={notificationsOpen} toggle={() => toggleNotifications()} nav id="basic-nav-dropdown"
                          className="ml-3">
                    <DropdownToggle nav caret className="navbar-dropdown-toggle">
                        <span className={`${s.avatar} rounded float-left mr-2`}>
                          <img src={countryFlag} alt="User"/>
                        </span>
                        <span
                            className="small d-none d-sm-block ml-1 mr-2 body-1">{user.name} {user.surname}
                        </span>
                    </DropdownToggle>
                    <DropdownMenu className="navbar-dropdown profile-dropdown"
                                  style={{width: "auto"}}>
                        <DropdownItem
                            className={s.dropdownProfileItem}
                            onClick={handleOpen}
                        >
                            <ProfileIcon/>
                            <span>Modifier le profile</span>
                        </DropdownItem>
                        <ScrollableModal
                            onClose={handleClose}
                            open={open}
                        >
                            <UpdateProfile user={user}/>
                        </ScrollableModal>
                        {isLoading && <Loader/>}
                        <NavItem className={"mt-2"}>

                            <NavLink>
                                <button onClick={() => dispatch(userLogout(token))}
                                        className="btn btn-primary rounded-pill mx-auto logout-btn" type="submit"><img
                                    src={logoutIcon} alt="Logout"
                                /><span className="ml-1">Déconnexion</span></button>

                            </NavLink>
                        </NavItem>
                    </DropdownMenu>
                </Dropdown>
            </Nav>
        </Navbar>
    )
}


export default withRouter(Header);

